var numeral = require("numeral")

const TableState = {
  tableItems: null,
  tableItemIndex: 0,
  tableMeta: {},
  skipSithReload: false,
}

const TableGetters = {
  tableItems: (state) => {
    return _.get(state, "tableItems")
  },
  tableItemIndex: (state) => {
    return _.get(state, "tableItemIndex")
  },
  tableMeta: (state) => {
    return _.get(state, "tableMeta")
  },
  activeSectionContext: (state) => {
    return _.get(state, "activeSectionContext")
  },
  skipSithReload: (state) => {
    return _.get(state, "skipSithReload")
  },
  currentPositionFormatted(state) {
    let currentPosition = _.get(state, "tableItemIndex")

    if (!_.isNumber(currentPosition)) {
      return
    }

    currentPosition += 1

    if (currentPosition >= 1000) {
      currentPosition = numeral(currentPosition).format("0,0")
    }

    return currentPosition
  },
  totalItemsFormatted(state) {
    let totalItems = _.get(state, "tableMeta.total_entries")

    if (!_.isNumber(totalItems)) {
      return
    }

    if (totalItems >= 1000) {
      totalItems = numeral(totalItems).format("0.0a")
    }

    return totalItems
  },
}

const TableActions = {
  reset: ({ commit, dispatch, rootGetters }) => {
    commit("RESET_STATE")
    if (rootGetters["fieldCardGrid/isEditing"]) {
      // reset edit state for field card grid when closing sheet
      dispatch("fieldCardGrid/toggleEditState", null, { root: true })
    }
  },
  set: ({ commit }, obj) => {
    commit("SET_VALUE", obj)
  },
  goToPreviousItem: ({ dispatch, getters }) => {
    if (!_.isEqual(getters.tableItemIndex, 0)) {
      const newPos = getters.tableItemIndex - 1
      const tableItem = getters.tableItems[newPos]

      if (tableItem) {
        let key
        const esIndex = _.get(tableItem, "_index")

        // ====
        // NULL CHECK
        // ====
        if (!esIndex) {
          console.error("_index is required to perform function.")
          return
        }
        // ====
        // GC COMPANIES
        // ====
        else if (/g[0-9]+_companies/.test(esIndex)) {
          if (_.get(getters.company, "renderTableCard")) {
            tableItem["renderTableCard"] = true
          }
          key = "company"
        }
        // ====
        // REPORTS
        // ====
        else if (/reports/.test(esIndex)) {
          key = "report"
        }
        // ====
        // UNHANDLED
        // ====
        else {
          console.error("Unhandled _index use case.")
          return
        }

        dispatch("set", {
          k: key,
          v: tableItem,
        })
        dispatch("set", {
          k: "tableItemIndex",
          v: newPos,
        })
      }
    }
  },
  goToNextItem: ({ dispatch, getters }) => {
    if (
      _.get(getters, "tableMeta.total_entries") > getters.tableItemIndex &&
      getters.tableItemIndex + 1 === _.get(getters.tableItems, "length") - 1
    ) {
      dispatch("sithState/paginateTable", null, { root: true })
    }

    const newPos = getters.tableItemIndex + 1
    const tableItem = getters.tableItems[newPos]

    if (tableItem) {
      let key
      const esIndex = _.get(tableItem, "_index")

      // ====
      // NULL CHECK
      // ====
      if (!esIndex) {
        console.error("_index is required to perform function.")
        return
      }
      // ====
      // GC COMPANIES
      // ====
      else if (/g[0-9]+_companies/.test(esIndex)) {
        if (_.get(getters.company, "renderTableCard")) {
          tableItem["renderTableCard"] = true
        }
        key = "company"
      }
      // ====
      // REPORTS
      // ====
      else if (/reports/.test(esIndex)) {
        key = "report"
      }
      // ====
      // UNHANDLED
      // ====
      else {
        console.error("Unhandled _index use case.")
        return
      }

      dispatch("set", {
        k: key,
        v: tableItem,
      })
      dispatch("set", {
        k: "tableItemIndex",
        v: newPos,
      })
    }
  },
  skipSithReload: ({ commit }, boolean) => {
    commit("SET_VALUE", { k: "skipSithReload", v: boolean })
  },
}

const TableMutations = {
  SET_VALUE(state, obj) {
    state[obj.k] = obj.v
  },
  SET_STATE(state, obj) {
    Object.assign(state, obj)
  },
  RESET_STATE(state) {
    Object.assign(state, {
      tableItems: null,
      tableItemIndex: 0,
      tableMeta: {},
      skipSithReload: false,
    })
  },
}

export { TableState, TableActions, TableGetters, TableMutations }
