import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import { mapGetters } from "vuex"
import { ReportTabTypes } from "@utils/mixins/reports/types"
import { ConfigureContexts } from "@mixins/app-contexts"

export const form = {
  mixins: [validationMixin],
  data() {
    return {
      name: null,
      loading: false,
      savedFilterId: null,
      integrationFormId: null,
      bulkAddToReport: null,
      reportType: ReportTabTypes.FILTER,
      reportTemplate: false,
      ReportTabTypes,
    }
  },
  computed: {
    ...mapGetters({
      activeNetwork: "auth/activeNetwork",
      activeGroup: "auth/activeGroup",
      activeUser: "auth/activeUser",
      savedFilters: "reports/savedFilters",
    }),
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) {
        return errors
      }
      !this.$v.name.required && errors.push("Name is required.")
      return errors
    },
    shouldWaitForParentRelation() {
      if (this.reportType === ReportTabTypes.FILTER) {
        return !this.savedFilterId
      }

      return false
    },
    createButtonText() {
      return this.reportType === ReportTabTypes.LIST &&
        _.get(this.meta, "reportTemplate")
        ? "Create Report"
        : "Next"
    },
    reportTypeOptions() {
      return [
        {
          type: ReportTabTypes.FILTER,
          icon: "filter_list",
          disabled: !!this.bulkAddToReport,
          title: "Add a filtered list of companies",
          subtitle:
            "This report tab will dynamically update to always display the results of the filter.",
        },
        {
          type: ReportTabTypes.LIST,
          icon: "playlist_add",
          disabled: false,
          title: "Add companies manually",
          subtitle:
            "This report tab will only display the companies you add to it.",
        },
      ]
    },
  },
  mounted() {
    this.name = _.get(this.meta, "name", null)
    this.integrationFormId = _.get(this.meta, "integrationFormId", null)
    this.savedFilterId = _.get(this.meta, "savedFilterId", null)
    this.bulkAddToReport = _.get(this.meta, "bulkAddToModel", null)
    this.reportTemplate = _.get(this.meta, "reportTemplate", false)
    this.reportType = this.bulkAddToReport
      ? ReportTabTypes.LIST
      : _.get(this.meta, "reportType", ReportTabTypes.FILTER)
    // edit state - load reports/model from store?
  },
  methods: {
    submit() {
      if (this.loading) {
        return
      }

      this.loading = true

      if (!this.$v.$invalid) {
        let reportParams
        let reportAction
        if (!this.reportTemplate) {
          reportAction = "reports/createReport"
          reportParams = {
            name: this.name,
            group_id: this.activeGroup.id,
            user_id: this.activeUser.id,
          }
        } else {
          reportAction = "reports/duplicateReport"
          reportParams = {
            report_id: this.reportTemplate,
            report: {
              name: this.name,
              group_id: this.activeGroup.id,
              user_id: this.activeUser.id,
            },
            rules: JSON.stringify({
              context_views: { all: true },
              headers: { all: true },
              companies: { all: false },
            }),
          }
        }

        // =========
        // Transition to other modals before creating report
        // =========
        // Allow the user to select a parent model (filter, form)
        // before creating the report or relational records.
        if (this.shouldWaitForParentRelation) {
          this.$store.dispatch("reports/setModel", reportParams)
          return this.openParentRelationDialog()
        }

        this.$store
          .dispatch(reportAction, reportParams)
          .then((newReport) => {
            return this.bulkAddToReport
              ? this.addModelsToNewReport(newReport)
              : this.cleanupAndClose(newReport)
          })
          .catch((e) => console.error(e))
      }
    },
    cleanupAndClose(newReport) {
      if (_.get(newReport, "duplicate_worker_id")) {
        this.openSidekiqStatusModal(newReport)

        setTimeout(() => {
          this.$store.dispatch("app/reloadBaseSith")
        }, 1000)
      } else {
        this.$router
          .push({
            name: "report-view",
            params: {
              id: _.get(newReport, "id"),
              permalink: _.get(newReport, "default_context_permalink"),
              bypassRedirect: true,
            },
          })
          .catch((e) => {})
        this.$emit("closeModal")
        this.resetValues()
      }
    },
    resetValues() {
      this.name = null
      this.savedFilterId = null
      this.integrationFormId = null
      this.reportType = ReportTabTypes.FILTER
      this.bulkAddToReport = null
      this.reportTemplate = false
    },
    formatId(action) {
      return `${_.lowerCase(action)}-button`
    },
    openSidekiqStatusModal(report) {
      this.$store.dispatch("app/setActiveDialog", {
        switchContextWithinDialog: true,
        title: "Report is being created",
        dialog: "activeModal",
        context: "SidekiqStatusModal",
        meta: {
          sidekiqWorkerId: report.duplicate_worker_id,
          failureHelpStates: [
            {
              question: "What happened?",
              answer:
                "Due to a server issue, we have temporarily locked this report.",
            },
            {
              question: "Will it be fixed?",
              answer:
                "Absolutely. Our team has been notified and is working to resolve this. We will be in touch soon to let you know when it will be unlocked. If you want to speak with us sooner, <a href='mailto:support@nucla.com'>click here</a>.",
            },
          ],
          transitionTo: `/reports/${report.id}`,
          submitButtonText: "Go to report",
          cancelButtonText: "Close",
          progressLabel: "Building report...",
          bonusAction: { func: "clearModelCache", argument: "reports" },
        },
      })
    },
    openReportTypeModal() {
      let params = {
        title: "New Report",
        action: "toggleDialog",
        dialog: "activeModal",
        context: "ReportTypeModal",
        meta: {
          creatingReport: true,
          actionSet: {
            set: "crud",
            action: "create",
          },
          name: this.name,
          bulkAddToModel: this.bulkAddToReport,
          reportTemplate: this.reportTemplate,
          callback: {
            backTo: {
              dialog: "activeModal",
              context: "ReportModal",
              title: "Create Report",
              cancelButtonText: "Back",
              submitButtonText: "Create Report",
              meta: {
                name: this.name,
                reportType: this.reportType,
                bulkAddToModel: this.bulkAddToReport,
                actionSet: {
                  set: "crud",
                  action: "create",
                },
              },
            },
          },
        },
      }

      this.$store.dispatch("app/setActiveDialog", {
        switchContextWithinDialog: true,
        dialog: "activeModal",
        title: params.title,
        subtitle: params.subtitle,
        context: params.context,
        meta: params.meta,
      })
    },
    openReportCustomTemplateModal() {
      let params = {
        title: "Custom Template",
        action: "toggleDialog",
        dialog: "activeModal",
        context: "ReportCustomTemplateModal",
        meta: {
          creatingReport: true,
          actionSet: {
            set: "crud",
            action: "create",
          },
          name: this.name,
          bulkAddToModel: this.bulkAddToReport,
          reportTemplate: this.reportTemplate,
          callback: {
            backTo: {
              dialog: "activeModal",
              context: "ReportModal",
              title: "Create Report",
              cancelButtonText: "Back",
              submitButtonText: "Create Report",
              meta: {
                name: this.name,
                reportType: this.reportType,
                bulkAddToModel: this.bulkAddToReport,
                actionSet: {
                  set: "crud",
                  action: "create",
                },
              },
            },
          },
        },
      }

      this.$store.dispatch("app/setActiveDialog", {
        switchContextWithinDialog: true,
        dialog: "activeModal",
        title: params.title,
        subtitle: params.subtitle,
        context: params.context,
        meta: params.meta,
      })
    },
    openParentRelationDialog() {
      let params = {
        title: "Add a filtered list of companies",
        action: "toggleDialog",
        dialog: "activeModal",
        context: "PrimarySavedFilterModal",
        meta: {
          name: this.name,
          creatingReport: true,
          reportTemplate: this.reportTemplate,
          submitButtonText: "Create Report",
          cancelButtonText: "Back",
          callback: {
            backTo: {
              dialog: "activeModal",
              context: "ReportTypeModal",
              title: "New Report",
              cancelButtonText: "Back",
              submitButtonText: "Create Report",
              meta: {
                name: this.name,
                reportType: this.reportType,
                reportTemplate: this.reportTemplate,
                creatingReport: true,
                actionSet: {
                  set: "crud",
                  action: "create",
                },
                callback: {
                  backTo: {
                    dialog: "activeModal",
                    context: "ReportModal",
                    title: "Create Report",
                    cancelButtonText: "Back",
                    submitButtonText: "Create Report",
                    meta: {
                      name: this.name,
                      reportType: this.reportType,
                      bulkAddToModel: this.bulkAddToReport,
                      actionSet: {
                        set: "crud",
                        action: "create",
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }

      if (this.reportType === ReportTabTypes.FORM) {
        params = {
          title: "Import companies from a form",
          context: "IntegrationFormModal",
          meta: {
            creatingReport: true,
            callback: {
              backTo: {
                dialog: "activeModal",
                context: "ReportModal",
                cancelButtonText: "Back",
                submitButtonText: "Create",
                meta: {
                  name: this.name,
                  reportType: this.reportType,
                  actionSet: {
                    set: "crud",
                    action: "create",
                  },
                },
              },
            },
          },
        }
      }

      this.$store.dispatch("app/setActiveDialog", {
        switchContextWithinDialog: true,
        dialog: "activeModal",
        title: params.title,
        subtitle: params.subtitle,
        context: params.context,
        meta: params.meta,
      })
    },
    openFieldSelectionModal() {
      let params = {
        title: "New Report",
        action: "toggleDialog",
        dialog: "activeModal",
        context: "ConfigureRelationalListModal",
        relationalModel: this.activeGroup,

        meta: {
          creatingReport: true,
          actionSet: {
            set: "crud",
            action: "create",
          },
          name: this.name,
          bulkAddToModel: this.bulkAddToReport,
          reportTemplate: this.reportTemplate,
          configContext: ConfigureContexts.TABLE,
          itemType: "ReportField",
          action: null,
          allItemsSource: "groupFields",
          filterAttr: "hid",
          relationalModel: this.activeGroup,
          callback: {
            backTo: {
              title: "New Report",
              action: "toggleDialog",
              dialog: "activeModal",
              context: "ReportTypeModal",
              meta: {
                creatingReport: true,
                actionSet: {
                  set: "crud",
                  action: "create",
                },
                name: this.name,
                bulkAddToModel: this.bulkAddToReport,
                reportTemplate: this.reportTemplate,
                callback: {
                  backTo: {
                    dialog: "activeModal",
                    context: "ReportModal",
                    title: "Create Report",
                    cancelButtonText: "Back",
                    submitButtonText: "Create Report",
                    meta: {
                      name: this.name,
                      reportType: this.reportType,
                      bulkAddToModel: this.bulkAddToReport,
                      actionSet: {
                        set: "crud",
                        action: "create",
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }

      this.$store.dispatch("app/setActiveDialog", {
        switchContextWithinDialog: true,
        dialog: "activeModal",
        title: params.title,
        subtitle: params.subtitle,
        context: params.context,
        meta: params.meta,
      })
    },
    addModelsToNewReport(newReport) {
      if (!newReport || !this.bulkAddToReport) {
        return
      }
      const plural = this.bulkAddToReport.length > 1
      const childModelType = _.get(this.bulkAddToReport, "[0].model_type", null)
      const childModelName = _.get(this.bulkAddToReport, "[0].name", null)
      const childModelKtName = _.get(this.bulkAddToReport, "[0].kt_name", null)
      let addedMessage = null

      if (plural) {
        addedMessage = `${this.bulkAddToReport.length} ${
          childModelType === "GroupCompany"
            ? "companies"
            : childModelType.toLowerCase()
        }`
      } else {
        addedMessage = childModelName || childModelKtName
      }

      // ---
      // Note: this will need to be expanded with bulk actions on table.
      // ---
      this.$store
        .dispatch("reports/bulkAddCompanies", {
          company_id_array: this.bulkAddToReport.map((i) => i.id),
          report_ids: [newReport.id],
        })
        .then((resp) => {
          this.cleanupAndClose(newReport)
          this.$store.dispatch("app/setSnackbar", {
            text: `Successfully created ${newReport.name} and added ${addedMessage}`,
            icon: "check_circle",
            iconColor: "#55AD5A",
          })
        })
    },
  },
  validations() {
    return {
      name: { required },
    }
  },
}
