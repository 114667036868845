import { createId } from "@paralleldrive/cuid2"
import moment from "moment"
import { FieldTypes } from "@mixins/fields/types"

const genBaseFieldGroup = (groupId) => {
  return {
    group_id: groupId,
    use_case: 10,
    taxonomy_id: null,
    definition: {
      id: "gtd_multi_field",
      label: null,
      type: "multifield",
      placeholder: null,
      description: null,
      required: false,
      hidden: false,
      store_multiple_responses: false,
      definitions: [],
    },
  }
}

const genBaseField = (fGroup) => {
  return {
    cuid: createId(),
    model_type: "Field",
    field_group_id: fGroup.id,
    created_at: fGroup.created_at || moment().format(),
    updated_at: fGroup.updated_at || moment().format(),
    group_id: fGroup.group_id,
    disable_options_menu: true,
  }
}

const genBaseFieldDatum = (fGroup) => {
  return {
    id: _.get(fGroup, "field_datum.id", null),
    model_type: "FieldDatum",
    created_at: fGroup.created_at || moment().format(),
    updated_at: fGroup.updated_at || moment().format(),
    field_group_id: fGroup.id,
    group_id: fGroup.group_id,
    group_company_id: fGroup.group_company_id,
    data: {},
    users: [],
    attachments: [],
  }
}

const genBaseResponse = (fGroup) => {
  if (!fGroup) {
    return console.error("Error: Invalid Field Group Cache State")
  }

  // ---
  // Step 1 - Build baseField and baseFieldDatum
  // ---

  const baseField = genBaseField(fGroup)
  const baseFieldDatum = genBaseFieldDatum(fGroup)

  // ---
  // Step 2 - Add Definition
  // ---

  const responseCuid = createId()
  const definitions = _.get(fGroup, "definition.definitions", [])

  const fields = _.map(definitions, (fDef) => {
    const nField = _.cloneDeep(baseField)
    const nFieldCuid = fDef.cuid || createId()
    nField.cuid = nFieldCuid
    nField.definition = fDef
    nField.use_case = FieldTypes[_.upperCase(fDef.type)]
    nField.response_cuid = responseCuid
    nField.field_datum = _.cloneDeep(baseFieldDatum)
    nField.field_datum.cuid = nFieldCuid
    return nField
  })

  // ---
  // Step 3 - Set and return "new" response
  // ---

  return {
    cuid: responseCuid,
    created_at: moment().format(),
    updated_at: moment().format(),
    model_type: "FieldGroupResponse",
    fields: fields,
  }
}

const parseResponses = (fGroup) => {
  if (!fGroup) {
    return console.error("Error: Invalid Field Group Cache State")
  }
  // ---
  // Step 1 - Build baseField and baseFieldDatum
  // ---

  const baseField = genBaseField(fGroup)
  const baseFieldDatum = genBaseFieldDatum(fGroup)

  // ---
  // Step 2 - Add Definition
  // ---
  const fields = []
  const definitions = _.get(fGroup, "definition.definitions", [])

  _.forEach(definitions, (fDef) => {
    const nField = _.cloneDeep(baseField)
    nField.definition = fDef
    nField.use_case = fDef.use_case
    nField.cuid = fDef.cuid || createId()
    fields.push(nField)
  })

  // ---
  // Step 2 - Associate Responses
  // ---

  let responses = _.cloneDeep(_.get(fGroup, "field_datum.data.responses", []))
  responses = _.map(responses, (resp) => {
    resp.model_type = "FieldGroupResponse"
    resp.field_group_id = fGroup.id
    resp.cuid = resp.cuid || createId()
    resp.attachments = _.get(fGroup, "field_datum.attachments", [])

    resp.fields = _.map(fields, (field) => {
      const rData = _.find(resp.response_data, { cuid: field.cuid })
      const idArray = _.get(rData, "value.id_array", null)

      field = _.cloneDeep(field)
      field.field_datum = _.cloneDeep(baseFieldDatum)
      field.field_datum.cuid = field.cuid
      field.field_datum.response_cuid = resp.cuid
      field.field_datum.data = _.get(rData, "value", null)

      if (idArray) {
        const fieldType = _.get(field, "definition.id", null)

        if (fieldType === "gtd_file") {
          const attachments = _.get(fGroup, "field_datum.attachments", [])
          field.field_datum.attachments = _.compact(
            _.map(attachments, (a) => {
              return idArray.includes(a.id) ? a : null
            })
          )
        }

        if (fieldType === "gtd_user") {
          const users = _.get(fGroup, "field_datum.users", [])
          field.field_datum.users = _.compact(
            _.map(users, (u) => {
              return idArray.includes(u.id) ? u : null
            })
          )
        }
      }
      return field
    })

    return resp
  })
  return responses
}

export { parseResponses, genBaseResponse, genBaseFieldGroup }
