// Typeform - developer.typeform.com

import { Providers } from "@mixins/integrations/providers"
import StaticHelper from "@mixins/static-helpers"
const { marked } = require("marked")

const cellLayoutMap = {
  choice: "SithText",
  choices: "SithChoices",
  date: "SithDate",
  email: "SithEmail",
  url: "SithLink",
  file_url: "SithLink",
  number: "SithNumber",
  boolean: "SithBoolean",
  text: "SithText",
  payment: "SithText",
  phone_number: "SithText",
  group: "SithText",
}

const companyNameTitles = ["name", "company name", "company"]

const companyLogoTitles = [
  "logo",
  "logo url",
  "company logo",
  "logo upload",
  "upload logo",
]

const companyUrlTitles = [
  "url",
  "website url",
  "company url",
  "company website",
  "company web site",
  "company site",
  "company address",
  "company web address",
  "website",
  "web site",
  "address",
  "web address",
  "website address",
  "domain",
  "company domain",
]

const getTypeformPath = function (id) {
  // Form - `https://developerplatform.typeform.com/to/${id}`
  // Dashboard - `https://admin.typeform.com/form/${id}/results#summary`
  return `https://admin.typeform.com/form/${id}/results#summary`
}

const getTypeformElementType = function (field) {
  if (["file_url", "website"].includes(field.type)) {
    return "UrlElement"
  }

  if (["phone_number", "group", "short_text"].includes(field.type)) {
    return "TextElement"
  }

  return `${_.capitalize(_.camelCase(field.type))}Element`
}

const getTypeformHeaders = function (responses) {
  const headerCache = []

  _.each(responses, function (resp) {
    const relateCompanies = resp.relate_companies

    _.each(resp.fields, function (f) {
      const skipHeader = relateCompanies && companyLogoTitles.includes(f.title)
      const alreadyCached = _.find(headerCache, { id: f.id })

      if (!skipHeader && !alreadyCached) {
        let layout = cellLayoutMap[f.type]

        if (relateCompanies) {
          if (companyNameTitles.includes(f.title)) {
            layout = "SithAvatarName"
          }
        }

        headerCache.push({
          id: f.id,
          text: f.title,
          value: f.type,
          layout: layout,
          align: "left",
        })
      }
    })
  })

  return headerCache
}

const getTypeformItems = function (responses) {
  const itemsCache = []

  _.each(responses, function (resp) {
    const item = { id: resp.id, submitted_at: resp.submitted_at }
    const relateCompanies = _.get(resp, "relate_companies", false)

    _.each(resp.fields, (f) => {
      item[f.id] = f[f.type]

      if (relateCompanies) {
        if (companyNameTitles.includes(f.title)) {
          item.company_name_field_id = f.id
        }
        if (companyLogoTitles.includes(f.title)) {
          item.company_logo_field_id = f.id
        }
        if (companyUrlTitles.includes(f.title)) {
          item.company_url_field_id = f.id
        }
      }
    })

    itemsCache.push(item)
  })

  return itemsCache
}

const getTypeformResponses = function (response) {
  const obj = _.flatMap(response.integration_forms, function (data) {
    const form = _.get(data, "form", null)
    const fields = _.get(data, "form.fields", null)
    const responses = _.get(data, "form_responses", null)
    const relateCompanies = _.get(data, "relate_companies", false)

    if (form && fields && responses) {
      return _.flatMap(responses, function (resp) {
        const answers = _.get(resp, "form_data.answers", null)

        if (answers) {
          return {
            id: resp.id,
            company_id: resp.company_id,
            title: form.title,
            form_id: form.id,
            integration_form_id: _.get(resp, "integration_form_id", null),
            submitted_at: _.get(resp, "form_data.submitted_at", null),
            fields: getTypeformFields(fields, answers),
            relate_companies: relateCompanies,
            provider: Providers.TYPEFORM,
          }
        }
      })
    }
  })

  return _.filter(obj)
}

const getTypeformFields = function (formFields, answers) {
  const fields = _.flatMap(formFields, function (f) {
    if (["group"].includes(f.type)) {
      const nestedFields = _.get(f, "properties.fields")
      return getTypeformFields(nestedFields, answers)
    }

    const answer = _.find(answers, { field: { id: f.id } })

    if (answer) {
      const type = answer.type
      let value = answer[type]

      return {
        id: _.get(answer, "field.id"),
        title: f.title,
        label: f.title, // This is added for autocomplete search functionality
        type: type,
        [type]: value,
        fieldChoices: _.get(f, "properties.choices"),
      }
    }
  })

  return _.filter(fields)
}

const setEditedTypeformResponse = function (editedForm, newAnswer) {
  const editedFormFields = _.cloneDeep(editedForm.fields)

  editedFormFields.map((f) => {
    if (f.id === newAnswer.field_id) {
      f = _.set(f, f.type, newAnswer.value)
    }

    return f
  })

  return editedFormFields
}

const setTypeformResponses = function (editedFormResponse, rawResponses) {
  const rawForm = _.cloneDeep(
    _.find(rawResponses, { id: editedFormResponse.id })
  )

  rawForm.form_data.answers.map((a) => {
    const editedField = _.find(editedFormResponse.fields, {
      id: _.get(a, "field.id"),
    })

    if (a[a.type] !== editedField[a.type]) {
      a = _.set(a, a.type, editedField[a.type])
    }

    return a
  })

  return rawForm
}

const formatTypeformHeaderText = function (text) {
  // Use proper bold markdown
  text = text.replace(/\*([^*]+)\*/i, "**$1**")

  // Disable # markdown by using the character code instead
  text = text.replace("#", "&num;")

  // Add Markdown support
  text = marked(text)

  // Scrub <p> tags from header
  text = text.replace(/(<p[^>]+?>|<p>|<\/p>)/g, "")

  // Scrub field/answer references (ie. "{{field:d0a2187e-4d81-4833-90db-26f1a7447fa6}}")
  text = text.replace(/({{field:[A-za-z0-9:-]+}})/g, "-")

  return text
}

const getFormFilterPath = function (iForm) {
  const formId = _.get(iForm, "integration_form_id") || _.get(iForm, "id")
  const formName = _.get(iForm, "title") || _.get(iForm, "form_name")
  const formVal = `${formId}${StaticHelper.wordify(formName)}`
  return {
    name: "group-view",
    query: {
      q: JSON.stringify({
        page: 1,
        per_page: 1000,
        f: {
          id: `f${formId}`,
          label: formName,
          filterData: [
            {
              label: "All Forms",
              attr: "sys_all_forms",
              fv: "AND",
              modOne: "IS ANY OF",
              valOne: [formVal],
              tag: formName,
              type: "terms",
              verb: "must",
              value: [formVal],
            },
          ],
        },
      }),
    },
  }
}

const getTypeformFileNameFromUrl = function (url) {
  if (StaticHelper.blank(url)) {
    return "typeform_file"
  }

  const fileUrlSplit = url.split("/")
  let fileName = fileUrlSplit[fileUrlSplit.length - 1]

  if (fileUrlSplit[fileUrlSplit.length - 2] === "files") {
    fileName = fileName.replace(/^[^-]+-/g, "")
  }

  return fileName
}

export {
  // Getters
  getTypeformResponses,
  getTypeformPath,
  getTypeformElementType,
  getTypeformHeaders,
  getTypeformItems,
  // Setters
  setTypeformResponses,
  setEditedTypeformResponse,
  // Helpers
  formatTypeformHeaderText,
  getFormFilterPath,
  getTypeformFileNameFromUrl,
}

// ---
// Notes
// ---

// Models
// - forms
// - responses
// - webhooks
// - themes
// - images
// - teams
// - workspaces

// Relations
// Account -> Team -> Workspace ...
// Form -> Response -> Field -> Answer

// Response Object
// https://developer.typeform.com/responses/JSON-response-explanation/

// FieldTypes:
// SHORT_TEXT: 0,
// LONG_TEXT: 1,
// DROPDOWN: 2,
// MULTI_CHOICE: 3, // single ("label") or collection ("labels")
// PICTURE_CHOICE: 4, // single ("label") or collection ("labels")
// EMAIL: 5,
// FILE_UPLOAD: 6,
// LEGAL: 7,
// YES_NO: 8,
// RATING: 9,
// OPINION_SCALE: 10,
// NUMBER: 11,
// DATE: 12,
// PAYMENT: 13,

// AnswerTypes:
// choice: 0, // Object - { label: 'Hello', other: 'Wat' }
// choices: 1, // Object - { labels: ['Hello', 'Hi'], other: 'Wat' }
// date: 2,
// email: 3,
// url: 4,
// file_url: 5,
// number: 6,
// boolean: 7,
// text: 8,
// payment: 9, // Object - { amount: '124', last4: '1234', name: 'Rico Suave' }
