// ===
// Vue i18n API
// https://kazupon.github.io/vue-i18n/api/
//
// Features:
//
// Various formats localization
// Pluralization
// DateTime localization
// Number localization
// Component based localization
// Component interpolation
// Fallback localization
// ===

export const messages = {
  // ===
  // Example usage in templates:
  // Pluralization
  // {{ $tc("message.company", 1) }} => company
  // {{ $tc("message.company", 2) }} => companies
  // {{ $tc("message.company", 10, { count: 10 }) }} => 10 companies
  // ===
  en: {
    message: {
      hello: "hello world",
      company: "company | companies | {count} companies",
    },
    // $vuetify: {
    //   dataIterator: {
    //     rowsPerPageText: "Items per page:",
    //     pageText: "{0}-{1} of {2}",
    //   },
    // },
  },
  fr: {
    message: {
      hello: "Bonjour monde",
    },
    // $vuetify: {
    //   dataIterator: {
    //     rowsPerPageText: "Objets par page:",
    //     pageText: "{0}-{1} de {2}",
    //   },
    // },
  },
}

export const dateTimeFormats = {
  // ===
  // Example usage in templates:
  // {{ $d(new Date(), 'short') }} => Jan 16, 2019
  // {{ $d(new Date(), 'long') }} => Wed, Jan 16, 2019, 9:08 AM
  // {{ $d(new Date(), 'long', 'fr') }} => dim. 16 janvier 2018 a 09:08
  // ===
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
  fr: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
}

export const numberFormats = {
  // ===
  // Example usage in templates:
  // {{ $n(100, 'currency') }} => $100.00
  // {{ $n(100, 'currency', 'fr') }} => 100,00 €
  // {{ $n(0.53, 'percent') }} => 53%
  // ===
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
    percent: {
      style: "percent",
    },
  },
  fr: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    percent: {
      style: "percent",
    },
  },
}
