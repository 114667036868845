export default {
  // List of potential user permission states:
  // Reg User
  // Group admin in one network
  // Group admin of multi groups in one network
  // Group admin in multi networks
  // Network admin
  // Network admin and reg user in another network
  // Network Admin and group admin in separate network
  // Network Admin of multi networks
  // Platform admin

  buildLevelOfAccess(ac) {
    // From Access Control on the user's session object, determine the
    // Level of Access the user has.

    // example of accessControl object ->
    // accessControl = {
    //   "networks": [
    //     id: 1,
    //     name: "VISA",
    //     admin: false,
    //     groups: [
    //       {
    //         id: 2,
    //         name: "Innovation",
    //         admin: true,
    //       }
    //     ]
    //   ]
    // }

    const networks = ac.networks ? ac.networks : []
    const adminAccess = {
      networks: [], // array of networks user can admin
      groups: [], // array of groups user can admin
    }

    // Cycle through each Network the user is apart of
    for (var i = 0; i < networks.length; i++) {
      // If admin, add to adminAccess["networks"]
      if (networks[i].admin) {
        adminAccess.networks.push({
          id: networks[i].id,
          name: networks[i].name,
        })
      }

      // Cycle through groups within network
      const groups = networks[i].groups
      for (var j = 0; j < groups.length; j++) {
        // if group admin or network admin add to adminAccess["groups"]
        if (groups[j].admin || networks[i].admin) {
          adminAccess.groups.push({
            id: groups[j].id,
            name: groups[j].name,
            netId: networks[i].id,
            netName: networks[i].name,
          })
        }
      }
    }

    return adminAccess
  },

  determineLevelOfAccess(ac) {
    // This function returns the first cachedSection for Breadcrumbs on SettingsWrapper

    const access = this.buildLevelOfAccess(ac)
    const networks = access.networks
    const groups = access.groups
    // Grab all the network Ids the user is a group admin of
    const groupAdNetIds = this.uniqGroupAdminNetworkIds(groups)

    if (ac.kite_admin || networks.length > 1 || groupAdNetIds.length > 1) {
      // ALL NETWORKS COMPONENT
      // User is an admin of multiple networks
      // User is an admin of one network and a group admin in another network
      // User is a group admin of multi networks
      return {
        label: "Admin Settings",
        component: "PlatformSettings",
      }
    } else if (
      networks.length === 1 ||
      this.isAdminOfManyGroupsInOneNetwork(access)
    ) {
      // NETWORK SETTINGS COMPONENT
      // User is the Network Admin
      // User is a group admin of multiple groups in same network
      let label, id

      // determine id and label
      if (networks[0]) {
        id = networks[0].id
        label = networks[0].name
      } else {
        id = groups[0].netId
        label = groups[0] ? groups[0].netName : "Network"
      }

      return {
        label: label,
        id: id,
        component: "NetworkSettings",
      }
    } else if (access.groups.length === 1) {
      // GROUP SETTINGS COMPONENT
      // User is a single Group Admin
      return {
        label: access.groups[0].name || "Group",
        id: access.groups[0].id,
        component: "GroupSettings",
      }
    } else {
      // USER SETTINGS COMPONENT
      // will return undefined and will load user data via SettingsWrapper
    }
  },

  isAdminOfManyGroupsInOneNetwork(access) {
    const groupAdNetIds = this.uniqGroupAdminNetworkIds(access.groups)
    return groupAdNetIds.length === 1 && access.groups.length > 1
  },

  uniqGroupAdminNetworkIds(groups) {
    return _.uniq(groups.map((g) => g.netId))
  },
}

// 1. Initialize to max hierarchy state depending on user session (breadcrumbs)
// 2. Add an easy way to open up user profile settings

// 3. Add way to pass in props that initializes user profile settings page
// instead of what the breadcrumbs would've loaded

// 4. Based on admin privileges, hide certain actions (create, disable, delete)
// 5. Ensure all views are using that admin meta. (Tables => Networks, Groups, Users in settings)
// a. test thoroughly

//  6. Add Users sub-tab on Networks or Groups.
