import store from "@state/store"

// ===
// MDN - Page Visibility API
// Helpers to follow the active tab in the browser.
// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
// ===

const addTabVisibilityListener = () => {
  // Set the name of the hidden property and the change event for visibility
  var hidden, visibilityChange
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden"
    visibilityChange = "visibilitychange"
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden"
    visibilityChange = "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden"
    visibilityChange = "webkitvisibilitychange"
  }

  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (
    typeof document.addEventListener === "undefined" ||
    hidden === undefined
  ) {
    console.error(
      "Browser doesn't support addEventListener or the Page Visibility API."
    )
  } else {
    // Handle page visibility change
    document.addEventListener(
      visibilityChange,
      () => {
        if (store.getters["auth/hasActiveSession"]) {
          store.dispatch("app/setActiveBrowserTabIsHidden", document[hidden])
        }
      },
      false
    )
  }
}

export { addTabVisibilityListener }
