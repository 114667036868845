const ConnectionUseCase = {
  GROUP_ACTIVE: 0,
  GROUP_SUBSCRIPTIONS: 1,
  GROUP_INTEGRATIONS: 2,
  GROUP_TRAY_INTEGRATIONS: 3,
  GROUP_REPORT: 4,
}

const SubscriptionTypes = {
  ALL: 0,
  READER_AND_PUBLISHER: 1,
  PUBLISHER: 2,
  READER: 3,
  INTEGRATIONS: 4,
  INACTIVE_SUBSCRIPTION: 5,
  TRAY_INTEGRATIONS: 6,
}

const SubscriptionTypeLabels = {
  0: "All Connections",
  1: "Collaborators",
  2: "Subscriptions",
  3: "Subscribers",
  4: "Integrations",
  5: "Inactive Connections",
}

const SubscriptionTypeIcons = {
  0: "settings_ethernet",
  1: "compare_arrows",
  2: "call_received",
  3: "call_made",
  4: "call_merge",
  5: "sync_disabled",
  6: "power",
}

const SubscriptionTypeTooltips = {
  0: "Everything your group has sent to and received from your connections", // All Connections
  1: "Posts can be sent to and received from this group", // Collaborator
  2: "Posts can be received from this group", // Subscription
  3: "Posts can be sent to this group or network", // Subscriber
  4: "External tools connected to your group", // Integration
  5: "No longer connected with this group or network", // Inactive Connection
}

const ConnectionSections = {
  OVERVIEW: 0,
  INTERNAL_TEAMS: 1,
  EXTERNAL_PARTNERS: 2,
  INTEGRATIONS: 3,
  PROVIDERS: 4,
}

const ConnectionCards = {
  PUBLISHERS: 0,
  GROUP: 1,
  READERS: 2,
}

export {
  ConnectionUseCase,
  SubscriptionTypes,
  SubscriptionTypeLabels,
  SubscriptionTypeIcons,
  SubscriptionTypeTooltips,
  ConnectionSections,
  ConnectionCards,
}
