export default {
  goTo(that) {
    const route = { name: that.path || that.name, params: {} }
    if (that.id) {
      route.params.id = that.id
    }
    if (that.saved_filter_id) {
      route.params.saved_filter_id = that.saved_filter_id
    }
    if (that.integration_form_id) {
      route.params.integration_form_id = that.integration_form_id
    }
    return route
  },
  keyFor(that) {
    let key = that.name
    if (that.key) {
      key = that.key
    }
    return key
  },
  openInNewTab(url) {
    // The following method seems to be the best current way to do this:
    // https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window-using-javascript
    const win = window.open(url, "_blank", "noopener noreferrer")
    win && win.focus()
  },
}
