export default {
  all: [],
  model: null,
  models: [],
  meta: null,
  loading: false,
  deleteLoading: false,
  errors: [],
  activeSearch: false,
  reloadKey: 0,
}
