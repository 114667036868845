export default {
  toggle(store, option) {
    store.dispatch("app/setActiveDialog", {
      dialog: option.dialog, // activeFullscreen, activeModal
      title: option.title, // Title of dialog
      context: option.context, // SettingsWrapper, fields-modal, etc.
      meta: option.meta || null,
      action: option.modalAction || null, // some modals need an action IE Delete/Edit (ConfirmationModal uses this)
      switchContextWithinDialog: option.switchContextWithinDialog || false,
      object: option.object || null, // Use object if you need to pass a model to a dialog.
    })
  },
}
