export default {
  canEdit(model, context = null) {
    if (model === "report") {
      const obj = context || {
        namespace: "can_edit",
        attr1: { a: "group_id" },
      }
      return this.compareAttrs(obj)
    }
  },

  reportImportState() {
    return { pattern: "report_import_state" }
  },

  isInGroup(model, groupId, context = null) {
    let obj = null

    if (model === "company") {
      obj = context || {
        namespace: "in_group",
        qModel: "GroupCompany",
        attr1: { a: "company_id" },
        attr2: { a: "group_id", v: groupId },
        attr3: { a: "marked_for_delete", v: false },
      }
      return this.findByThree(obj)
    }

    if (model === "integrationForm") {
      obj = context || {
        namespace: "in_group",
        qModel: "IntegrationForm",
        attr1: { a: "external_id" },
        attr2: { a: "group_id", v: groupId },
      }
      return this.findByTwo(obj)
    }
  },

  isAccessibleInGroup(groupId) {
    return {
      pattern: "accessible_in_group",
      group_id: groupId
    }
  },

  isInReport(reportId) {
    return {
      namespace: "in_report",
      pattern: "is_in_report",
      report_id: reportId,
    }
  },

  groupModelId(model, groupId, context = null) {
    if (model === "company") {
      const obj = context || {
        namespace: "group_company_id",
        nDef: "model_introspec?",
        qModel: "GroupCompany",
        mAttr: "id",
        attr1: { a: "company_id" },
        attr2: { a: "group_id", v: groupId },
        attr3: { a: "marked_for_delete", v: false },
      }
      return this.findByThree(obj)
    }
  },

  isAdmin(model) {
    const obj = {
      pattern: "find_by_two_attr",
      namespace: `${model.model_type.toLowerCase()}_admin`,
      n_def: "model_introspec?",
      q_model: "Policy",
      m_attr: "admin",
      attr1: { a: "child_user_id" },
      attr2: {
        a: `${model.model_type.toLowerCase()}_id`,
        v: model.id,
      },
    }
    return obj
  },

  reportSharedWith() {
    return {
      pattern: "send_method_on_result",
      namespace: "shared_with",
      n_def: "send_method",
      q_model: "Report",
      attr1: { a: "shared_with" },
    }
  },

  reportManuallyAddedCompanies(reportId) {
    return {
      pattern: "report_manually_added_companies",
      report_id: reportId,
    }
  },

  sourcesRelationalData(groupId = null) {
    return {
      pattern: "sources_relational_data",
      group_id: groupId,
    }
  },

  widgetRelationalData() {
    return {
      pattern: "widget_relational_data",
    }
  },

  favoritesRelationalData() {
    return {
      pattern: "favorite_relational_data",
    }
  },

  reportFavorites() {
    return {
      pattern: "report_favorites",
    }
  },

  reportRecentActivity() {
    return {
      pattern: "report_recent_activity",
    }
  },

  activityRelationalData() {
    return {
      pattern: "activity_relational_data_v2",
    }
  },

  commentRelationalData() {
    return {
      pattern: "comment_relational_data",
    }
  },

  notificationRelationalData() {
    return {
      pattern: "notification_relational_data",
    }
  },

  reportFollowState() {
    return {
      pattern: "report_follow_state",
    }
  },

  reportFollowCount() {
    return {
      pattern: "report_follow_count",
    }
  },

  // PATTERNS
  findByTwo(obj) {
    // PARAMS
    // namespace    ->   Namespace. Name of custom attribute to add to model.
    // n_def        ->   Namespace Definition. Function used to determine value of namespace.
    // q_model      ->   Query Model. Model to query for.
    // m_attr       ->   Model Attribute. Used when checking an attribute on the model returned from q_model. OPTIONAL -- required if n_def is "model_introspec?"
    // attr1        ->   Attribute One. used to query for q_model.
    //   -`a`           --> attribute. Name of attribute.
    //   -`res_v` - result value. Expected value of attr1["a"]. OPTIONAL -- if blank, will default to result id value.
    // attr2        ->   Attribute Two. Used to query for q_model.
    //   -`a`           --> attribute. Name of attribute.
    //   -`v`           --> value. Value of attribute.
    return {
      pattern: "find_by_two_attr",
      n_def: obj.nDef ? obj.nDef : "model_exists?",
      namespace: obj.namespace ? obj.namespace : "custom_attr",
      q_model: obj.qModel ? obj.qModel : undefined,
      m_attr: obj.mAttr ? obj.mAttr : undefined,
      attr1: obj.attr1 ? obj.attr1 : undefined,
      attr2: obj.attr2 ? obj.attr2 : undefined,
    }
  },
  findByThree(obj) {
    // PARAMS
    // namespace    ->   Namespace. Name of custom attribute to add to model.
    // n_def        ->   Namespace Definition. Function used to determine value of namespace.
    // q_model      ->   Query Model. Model to query for.
    // m_attr       ->   Model Attribute. Used when checking an attribute on the model returned from q_model. OPTIONAL -- required if n_def is "model_introspec?"
    // attr1        ->   Attribute One. used to query for q_model.
    //   -`a`           --> attribute. Name of attribute.
    //   -`res_v` - result value. Expected value of attr1["a"]. OPTIONAL -- if blank, will default to result id value.
    // attr2        ->   Attribute Two. Used to query for q_model.
    //   -`a`           --> attribute. Name of attribute.
    //   -`v`           --> value. Value of attribute.
    // attr3:       -> { a: "marked_for_delete", v: false } ->   Attribute Three. Used to query for q_model.
    //   -`a`           --> attribute. Name of attribute.
    //   -`v`           --> value. Value of attribute.
    return {
      pattern: "find_by_three_attr",
      n_def: obj.nDef ? obj.nDef : "model_exists?",
      namespace: obj.namespace ? obj.namespace : "custom_attr",
      q_model: obj.qModel ? obj.qModel : undefined,
      m_attr: obj.mAttr ? obj.mAttr : undefined,
      attr1: obj.attr1 ? obj.attr1 : undefined,
      attr2: obj.attr2 ? obj.attr2 : undefined,
      attr3: obj.attr3 ? obj.attr3 : undefined,
    }
  },
  compareAttrs(obj) {
    // PARAMS
    // namespace    ->   Namespace. Name of custom attribute to add to model.
    // n_def        ->   Namespace Definition. Function used to determine value of namespace.
    // q_model      ->   Query Model. Model to find and compare attribute against. OPTIONAL -- if q_model is blank, will default to @current_session object, else attr2 is required.
    // attr1        ->   Attribute One. Attribute used to compare between result and query model.
    //   -`a`           --> attribute. Name of attribute.
    // attr2        ->   Attribute Two. Used to query for q_model. OPTIONAL -- required if q_model is provided
    //   -`a`           --> attribute. Name of attribute.
    //   -`v`           --> value. Value of attribute.
    return {
      pattern: "compare_two_attr",
      n_def: obj.nDef ? obj.nDef : "identical_attr?",
      namespace: obj.namespace ? obj.namespace : "custom_attr",
      q_model: obj.qModel ? obj.qModel : undefined,
      attr1: obj.attr1 ? obj.attr1 : undefined,
      attr2: obj.attr2 ? obj.attr2 : undefined,
    }
  },
}
