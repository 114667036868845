import axios from "axios"
import SearchMixin from "@mixins/search"
import _ from "lodash"
import CustomAttrs from "@mixins/custom-attributes"
_.mixin(require("lodash-inflection"))

export default {
  index: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    const searchObject = SearchMixin.buildSearchObject(payload)
    axios
      .get(`search?q=${searchObject}`)
      .then((response) => {
        commit("SET_ALL", response)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  show: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    const modelNamespace = _.snakeCase(_.pluralize(payload.model))

    return axios
      .get(`${modelNamespace}/${payload.id}${payload.params || ""}`)
      .then((response) => {
        if (!_.get(payload, "skipSet", false)) {
          commit("SET_MODEL", response.data[payload.model])
          commit("SET_INCLUDE", response.data)
        }
        return response.data[payload.model]
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  create: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    var modelNamespace = _.snakeCase(payload.model)

    axios
      .post(_.pluralize(modelNamespace), payload.params)
      .then((response) => {
        commit("SET_MODEL", response.data[modelNamespace])
        commit("PUSH_ALL", response)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  showPromise: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")

      const modelNamespace = _.snakeCase(_.pluralize(payload.model))
      const dataNamespace = _.snakeCase(_.lowerCase(payload.model))

      axios
        .get(`${modelNamespace}/${payload.id}${payload.params || ""}`)
        .then((response) => {
          commit("SET_MODEL", response.data[dataNamespace])
          commit("SET_INCLUDE", response.data)
          resolve(response.data[dataNamespace])
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  createPromise: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")

      var modelNamespace = _.snakeCase(payload.model)
      axios
        .post(_.pluralize(modelNamespace), payload.params)
        .then((response) => {
          commit("SET_MODEL", response.data[modelNamespace])
          commit("PUSH_ALL", response)
          resolve(response.data[modelNamespace])
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
        })
    })
  },
  async awaitCreate({ commit }, payload) {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")
    var modelNamespace = _.snakeCase(payload.model)

    await axios
      .post(_.pluralize(modelNamespace), payload.params)
      .then((response) => {
        commit("SET_MODEL", response.data[modelNamespace])
        commit("PUSH_ALL", response)
        return Promise.resolve(response.data[modelNamespace])
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
        return Promise.resolve(null)
      })
  },
  update: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    const modelNamespace = _.snakeCase(payload.model)
    let q = null

    // Compile additional custom query params
    if (payload.model === "report") {
      q = JSON.stringify({
        custom_attrs: [
          CustomAttrs.canEdit(modelNamespace),
          CustomAttrs.reportImportState(),
        ],
      })
    }

    return axios
      .put(
        _.pluralize(modelNamespace) + "/" + payload.id + (q ? `?q=${q}` : ""),
        payload.params
      )
      .then((response) => {
        commit("SET_MODEL", response.data[modelNamespace])
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  updatePromise: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")

      const modelNamespace = _.snakeCase(payload.model)
      let q = null

      // Compile additional custom query params
      if (payload.model === "report") {
        q = JSON.stringify({
          custom_attrs: [
            CustomAttrs.canEdit(modelNamespace),
            CustomAttrs.reportImportState(),
          ],
        })
      }

      return axios
        .put(
          _.pluralize(modelNamespace) + "/" + payload.id + (q ? `?q=${q}` : ""),
          payload.params
        )
        .then((response) => {
          commit("SET_MODEL", response.data[modelNamespace])
          resolve(response.data[modelNamespace])
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
        })
    })
  },
  async awaitUpdate({ commit }, payload) {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    const modelNamespace = _.snakeCase(payload.model)
    let q = null

    // Compile additional custom query params
    if (payload.model === "report") {
      q = JSON.stringify({
        custom_attrs: [
          CustomAttrs.canEdit(modelNamespace),
          CustomAttrs.reportImportState(),
        ],
      })
    }

    await axios
      .put(
        _.pluralize(modelNamespace) + "/" + payload.id + (q ? `?q=${q}` : ""),
        payload.params
      )
      .then((response) => {
        commit("SET_MODEL", response.data[payload.model])
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
        return Promise.resolve(null)
      })
  },
  delete: ({ commit, dispatch }, payload) => {
    commit("SET_LOADING", true)
    commit("SET_DELETE_LOADING", true)
    commit("CLEAR_ERRORS")

    var modelNamespace = _.snakeCase(payload.model)
    let signalName = `deleted${_.camelCase(payload.model).replace(
      /\w/,
      _.capitalize
    )}`

    axios
      .delete(_.pluralize(modelNamespace) + "/" + payload.id)
      .then(() => {
        // Users are able to delete reports from company profiles while
        // viewing a report. Set model to null if you're working with
        // that particular model.
        if (payload.id === _.get(state, "model.id")) {
          commit("SET_MODEL", null)
        }
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("SET_DELETE_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
        dispatch(
          "signals/setSignal",
          {
            k: signalName,
            v: payload.id,
          },
          { root: true }
        )
      })
  },
  deletePromise: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("SET_DELETE_LOADING", true)
      commit("CLEAR_ERRORS")

      var modelNamespace = _.snakeCase(payload.model)

      axios
        .delete(_.pluralize(modelNamespace) + "/" + payload.id)
        .then(() => {
          commit("SET_MODEL", null)
          resolve(null)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          commit("SET_DELETE_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
          dispatch(
            "signals/setSignal",
            {
              k: `deleted${_.camelCase(payload.model).replace(
                /\w/,
                _.capitalize
              )}`,
              v: payload.id,
            },
            { root: true }
          )
        })
    })
  },
  async awaitDelete({ commit }, payload) {
    commit("SET_LOADING", true)
    commit("SET_DELETE_LOADING", true)
    commit("CLEAR_ERRORS")

    var modelNamespace = _.snakeCase(payload.model)

    await axios
      .delete(_.pluralize(modelNamespace) + "/" + payload.id)
      .then(() => {
        commit("SET_MODEL", null)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("SET_DELETE_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
        return Promise.resolve(null)
      })
  },
  createAll: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    var modelNamespace = _.snakeCase(payload.model)
    const createPayload = JSON.stringify(payload.selected)

    axios
      .post(_.pluralize(modelNamespace) + "/batch", {
        [_.pluralize(modelNamespace)]: createPayload,
      })
      .then((response) => {
        commit("SET_ALL", response)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  createAllPromise: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")

      var modelNamespace = _.snakeCase(payload.model)
      const createPayload = JSON.stringify(payload.selected)

      axios
        .post(_.pluralize(modelNamespace) + "/batch", {
          [_.pluralize(modelNamespace)]: createPayload,
        })
        .then((response) => {
          commit("SET_ALL", response)
          resolve(response.data[_.pluralize(modelNamespace)])
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
        })
    })
  },
  deleteAll: ({ commit, dispatch }, payload) => {
    commit("SET_LOADING", true)
    commit("SET_DELETE_LOADING", true)
    commit("CLEAR_ERRORS")

    const modelIds = _.map(payload.selected, (m) => ({ id: m.id }))

    var modelNamespace = _.snakeCase(payload.model)
    const deletePayload = JSON.stringify(modelIds)
    let signalName = `deleted${_.camelCase(payload.model).replace(
      /\w/,
      _.capitalize
    )}`

    axios
      .delete(_.pluralize(modelNamespace) + "/batch", {
        params: {
          [_.pluralize(modelNamespace)]: deletePayload,
        },
      })
      .then(() => {
        commit("SET_ALL", null)
        dispatch(
          "signals/setSignal",
          {
            k: signalName,
            v: deletePayload,
          },
          { root: true }
        )
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("SET_DELETE_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  deleteAllPromise: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("SET_DELETE_LOADING", true)
      commit("CLEAR_ERRORS")

      var modelNamespace = _.snakeCase(payload.model)
      const deletePayload = JSON.stringify(payload.selected)

      axios
        .delete(_.pluralize(modelNamespace) + "/batch", {
          params: {
            [_.pluralize(modelNamespace)]: deletePayload,
          },
        })
        .then(() => {
          commit("SET_ALL", null)
          resolve(null)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          commit("SET_DELETE_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
        })
    })
  },
  updateAll: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    var modelNamespace = _.snakeCase(payload.model)
    const updatePayload = JSON.stringify(payload.selected)

    axios
      .put(_.pluralize(modelNamespace) + "/batch", {
        [_.pluralize(modelNamespace)]: updatePayload,
      })
      .then((response) => {
        if (!payload.skipCallbacks) {
          commit("SET_ALL", response)
        }
      })
      .catch((e) => {
        if (!payload.skipCallbacks) {
          commit("SET_ERRORS", e)
        } else {
          console.error(e)
        }
      })
      .finally(() => {
        if (!payload.skipCallbacks) {
          commit("SET_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
        }
      })
  },
  updateAllPromise: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")

      var modelNamespace = _.snakeCase(payload.model)
      const updatePayload = JSON.stringify(payload.selected)

      axios
        .put(_.pluralize(modelNamespace) + "/batch", {
          [_.pluralize(modelNamespace)]: updatePayload,
        })
        .then((response) => {
          if (!payload.skipCallbacks) {
            commit("SET_ALL", response)
          }
          resolve(response.data[_.pluralize(modelNamespace)])
        })
        .catch((e) => {
          if (!payload.skipCallbacks) {
            commit("SET_ERRORS", e)
          }
          reject(e)
        })
        .finally(() => {
          if (!payload.skipCallbacks) {
            commit("SET_LOADING", false)
            commit("INCREMENT_RELOAD_KEY")
          }
        })
    })
  },
  toggleSearch: ({ commit }, namespace) => {
    commit("TOGGLE_STATE", namespace)
  },
  resetState: ({ commit }) => {
    commit("RESET_STATE")
  },
  setModel: ({ commit }, model) => {
    commit("SET_MODEL", model)
  },
  resetModel: ({ commit }) => {
    commit("SET_MODEL", null)
  },
  setModels: ({ commit }, models) => {
    commit("SET_MODELS", models)
  },
  resetModels: ({ commit }) => {
    commit("SET_MODELS", [])
  },
  incrementReloadKey: ({ commit }) => {
    commit("INCREMENT_RELOAD_KEY")
  },
  clearErrors: ({ commit }) => {
    commit("CLEAR_ERRORS")
  },
}
