const FavoriteUseCase = {
  GROUP: 0,
  USER: 1,
}

const FavoriteType = {
  REPORT: 0,
}

export { FavoriteUseCase, FavoriteType }
