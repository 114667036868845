import helpers from "@mixins/static-helpers"
export default {
  money(value) {
    if (helpers.blank(value)) {
      return
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    })
    return formatter.format(value)
  },
  abbreviateMoney(number, currencyCode) {
    return abvMoney(number, currencyCode)
  },
  abbreviateNumber(number, abbrev) {
    return abvNumber(number, abbrev)
  },
}

function abvNumber(number, abbrev) {
  if (!number && number !== 0) {
    return ""
  }
  let i, size
  const p = Math.pow
  const d = p(10, 1)
  let n = Math.abs(number)
  abbrev = helpers.blank(abbrev) ? ["k", "m", "bn", "t"] : abbrev
  i = abbrev.length - 1
  while (i >= 0) {
    size = p(10, (i + 1) * 3)
    if (size <= n) {
      n = Math.round((n * d) / size) / d
      if (n === 1000 && i < abbrev.length - 1) {
        n = 1
        i++
      }
      n += abbrev[i]
      break
    }
    i--
  }
  return n
}

function abvMoney(number, currencyCode) {
  if (!number) {
    return ""
  }
  currencyCode = !currencyCode ? "USD" : currencyCode
  const fullNumberWithCurrency = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  }).format(number)
  const fullNumberWithoutCurrency = fullNumberWithCurrency.replace(
    /[^0-9.,]/g,
    ""
  )
  const abbreviatedNumber = abvNumber(number)
  return fullNumberWithCurrency.replace(
    fullNumberWithoutCurrency,
    abbreviatedNumber
  )
}
