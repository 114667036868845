<template>
  <v-container fluid class="timeout-container">
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12">
        <BaseAvatar
          :src="require('@images/nucla/nucla-logo-light.svg')"
          class="white-label logo"
          size="170px"
          tile
        />
        <h3>The page timed out while loading.</h3>
        <h5>Are you sure you're still connected to the Internet?</h5>
        <br />
        <v-btn
          v-if="activeUser"
          color="primary"
          class="white--text"
          @click="transitionTo({ external: 'https://help.nucla.com/' })"
        >
          Need help?
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  page: {
    title: "Page timeout",
  },
  computed: {
    ...mapGetters({
      activeUser: "auth/activeUser",
    }),
  },
  methods: {
    transitionTo(params) {
      if (params.external) {
        window.open(params.external, "_blank", "noopener noreferrer")
      } else {
        this.$router.push(params)
      }
    },
  },
}
</script>

<style scoped lang="scss">
div.timeout-container {
  height: 50vh;
  min-height: 500px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
</style>
