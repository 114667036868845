const DataSources = {
  KITE: 0,
  GROUP: 1,
  CRUNCHBASE: 2,
  CLEARBIT: 3,
  OWLER: 4,
  TYPEFORM: 5,
  SHARED: 6,
  SYSTEM: 7,
  SIMILAR_WEB: 8, // No longer supported
  PIGEONHOLE: 9,
  SALESFORCE: 10,
  MICROSOFT_DYNAMICS_365: 11,
  AFFINITY: 12,
  PITCHBOOK: 13,
  FLATFILE: 14,
}

const DataSourcesHumanReadable = {
  0: "Platform",
  1: "Proprietary",
  2: "Crunchbase",
  3: "Clearbit",
  4: "Owler",
  5: "Typeform",
  6: "Shared",
  7: "Typeform", // system fields
  8: "SimilarWeb", // No longer supported
  9: "Pigeonhole",
  10: "Salesforce",
  11: "Microsoft Dynamics 365",
  12: "Affinity",
  13: "PitchBook",
  14: "Company Imports", // aka Flatfile
}

const DataSourceCategories = {
  0: "Proprietary",
  1: "Proprietary",
  2: "Provider",
  3: "Provider",
  4: "Provider",
  5: "Proprietary",
  6: "Shared",
  7: "Proprietary", // system fields
  8: "Provider", // No longer supported
  9: "Provider",
  10: "Provider",
  11: "Provider",
  12: "Provider",
  13: "Provider",
}

const DataSourceLogos = {
  0: null,
  1: null,
  2: "https://ryse-static.s3.amazonaws.com/logos/crunchbase-icon.svg",
  3: "https://ryse-static.s3.amazonaws.com/logos/clearbit-icon.svg",
  4: "https://ryse-static.s3.amazonaws.com/logos/owler-logo.png",
  5: "https://ryse-static.s3.amazonaws.com/logos/typeform-icon.svg",
  6: null,
  7: null,
  8: "https://ryse-static.s3.amazonaws.com/logos/similar-web-logo.png",
  9: "https://ryse-static.s3.amazonaws.com/logos/pigeonhole.png",
  10: "https://ryse-static.s3.amazonaws.com/logos/salesforce.png",
  11: "https://ryse-static.s3.amazonaws.com/logos/microsoft-dynamics-365.png",
  12: "https://ryse-static.s3.amazonaws.com/logos/affinity.png",
  13: "https://ryse-static.s3.amazonaws.com/logos/pitchbook-icon.svg",
  14: "https://ryse-static.s3.amazonaws.com/logos/spreadsheets.svg",
}

const DataSourceDescriptions = {
  0: null,
  1: null,
  2: "Connect to bring in real-time data on millions of emerging-tech companies worldwide. ",
  3: "Create forms and surveys to collect information from partners.",
  4: "Layer in up-to-date business information and news curated by millions of business professionals.",
  5: "Create forms and surveys to collect information from partners.",
  6: null,
  7: null,
  8: "Create forms and surveys to collect information from partners.",
  9: null,
  10: "World’s #1 customer relationship management (CRM) platform.",
  11: "Bring everyone together across your business with Dynamics.",
  12: "Relationship intelligence platform built to expand and evolve the CRM.",
  13: "Independent and impartial research firm dedicated to providing premium data, news and analysis.",
  14: null,
}

const DataProviderSources = [
  DataSources.CRUNCHBASE,
  DataSources.CLEARBIT,
  DataSources.OWLER,
  // DataSources.SIMILAR_WEB,
  DataSources.PITCHBOOK,
]

const freeDataSources = [
  DataSources.CRUNCHBASE,
  DataSources.CLEARBIT,
  DataSources.OWLER,
  // DataSources.SIMILAR_WEB,
]

const premiumDataSources = [DataSources.PITCHBOOK]

const IntegrationDataSources = [
  DataSources.TYPEFORM,
  DataSources.SALESFORCE,
  DataSources.MICROSOFT_DYNAMICS_365,
  DataSources.AFFINITY,
  DataSources.FLATFILE,
]

const TrayDataSources = [
  DataSources.SALESFORCE,
  DataSources.MICROSOFT_DYNAMICS_365,
  DataSources.AFFINITY,
]

const SystemDataSources = [DataSources.KITE, DataSources.SYSTEM]

const sourceLogo = function (item) {
  if (item.source === DataSources.SHARED) {
    return item.source_logo
  } else {
    return DataSourceLogos[item.source] || item.logo || item.owner_group_logo
  }
}

const sourceName = function (item) {
  if (
    [
      DataSources.SHARED,
      DataSources.SALESFORCE,
      DataSources.MICROSOFT_DYNAMICS_365,
      DataSources.TYPEFORM,
    ].includes(item.source)
  ) {
    return item.source_name
  } else {
    return (
      DataSourcesHumanReadable[item.source] ||
      item.name ||
      item.owner_network_name
    )
  }
}

const parseDataSourceFromAttribute = function (i) {
  if (i.attribute && i.attribute.includes("_")) {
    const val = _.split(i.attribute, "_")[0]

    // 0 - platform
    // 1 - proprietary

    if (val === "cb") {
      return DataSources.CRUNCHBASE
    }

    if (val === "clb") {
      return DataSources.CLEARBIT
    }

    if (val === "owl") {
      return DataSources.OWLER
    }

    if (val === "if" || val.match(/^if[0-9]+$/)) {
      return DataSources.TYPEFORM
    }

    if (val === "sf") {
      return DataSources.SHARED
    }

    // if (val === "sw") {
    //   return DataSources.SIMILAR_WEB
    // }

    if (val === "pb") {
      return DataSources.PITCHBOOK
    }

    if (val === "slf" || val.match(/^slf[0-9]+$/)) {
      return DataSources.SALESFORCE
    }

    if (val === "msd365" || val.match(/^msd365[0-9]+$/)) {
      return DataSources.MICROSOFT_DYNAMICS_365
    }

    if (val === "afi" || val.match(/^afi[0-9]+$/)) {
      return DataSources.AFFINITY
    }
  }

  return null
}

export {
  DataSources,
  DataSourceLogos,
  DataSourceCategories,
  DataSourcesHumanReadable,
  DataSourceDescriptions,
  parseDataSourceFromAttribute,
  sourceLogo,
  sourceName,
  IntegrationDataSources,
  DataProviderSources,
  TrayDataSources,
  SystemDataSources,
  freeDataSources,
  premiumDataSources,
}
