const AppContexts = {
  // The following contexts use the format:
  // (INDEX)_(TABLE/FEED)
  GC_COMPANIES: 0,
  REPORT_SHOW_REPORT_SCOPE_FILTER: 1,
  POSTS_ECOSYSTEM: 2,
  USERS_TEAMLISTING: 3,
  REPORTS_REPORTS: 4,
  NETWORKS_SETTINGS: 5,
  GROUPS_SETTINGS: 6,
  USERS_SETTINGS: 7,
  COMPANY_SHOW: 8,
  REPORT_SHOW: 9,
  REPORT_SHOW_GROUP_SCOPE_FILTER: 10,
  FORMS_TABLE: 11,
  COMPANY_DRAWER: 12,
  IMPORT_RECORDS_TABLE: 13,
  GC_KANBAN_BOARD: 14,
  NETWORK_COMPANIES: 15,
  NETWORK_GROUPS: 16,
  GROUP_DASHBOARD: 17,
  GROUP_FEED: 18,
  REPORT_DASHBOARD: 19,
  REPORT_FEED: 20,
  INTEGRATIONS_TABLE: 21,
  CONTAINERS_SHOW_TABLE: 22,
  DATA_PROVIDER_CONTAINERS: 23,
  COMPANY_REPORTS: 24,
  FOLLOWING: 25,
  GROUPS_SHOW: 26,
}

const ConfigureContexts = {
  TABLE: 0,
  SAVED_FILTER_RELATION: 1,
  FORM_RESPONSE_TABLE: 2,
  REPORT_HEADER_ORDER: 3,
  FIELD_CARD_TEMPLATE_COMPANY_PROFILE: 4,
}

const SharedSavedFilterAppContexts = {
  0: [0],
  1: [1],
  2: [2],
  3: [3, 7],
  4: [4],
  5: [5],
  6: [6],
  7: [3, 7],
  11: [11],
}

const ContextViewStates = {
  TABLE: 0,
  DASHBOARD: 1,
  ACTIVITY: 2,
  REPORTS: 3,
  NEWS: 4,
  NOTES: 5, // Deprecated September 2023
  FILES: 6,
  HOME: 7,
  GROUPS: 8,
  CONTEXTS: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  CONTEXT_NAMES: [
    "Table",
    "Dashboard",
    "Activity",
    "Reports",
    "News",
    "Notes",
    "Files",
    "Home",
    "Groups",
  ],
  CONTEXT_DISPLAY_NAMES: [
    "Table",
    "Dashboard",
    "Activity",
    "Reports",
    "News",
    "Notes",
    "PDF Viewer",
    "Home",
    "Groups",
  ],
  CONTEXT_ICONS: [
    "table_chart",
    "insert_chart",
    "show_chart",
    "dataset",
    "newspaper",
    "chat",
    "picture_as_pdf",
    "home",
    "groups",
  ],
}

const PostContexts = {
  GROUP: 0,
  NETWORK: 1,
  ECOSYSTEM: 2,
  CONTEXTS: [0, 1, 2],
  CONTEXT_NAMES: ["Group", "Network", "Ecosystem"],
  CONTEXT_ICONS: ["business", "business", "language"],
}

const ShareContexts = {
  SHARED_BY_ACTIVE_GROUP: 0,
  SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_GROUP: 1,
  SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_GROUP: 2,
  SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_NETWORK: 3,
  SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_NETWORK: 4,
  SHARED_FROM_ALL: 5,
}

const ShareContextGroups = {
  SHARED_ALL: [
    ShareContexts.SHARED_BY_ACTIVE_GROUP,
    ShareContexts.SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_GROUP,
    ShareContexts.SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_GROUP,
    ShareContexts.SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_NETWORK,
    ShareContexts.SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_NETWORK,
  ],
  SHARED_FROM_WITHIN_ACTIVE_NETWORK: [
    ShareContexts.SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_GROUP,
    ShareContexts.SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_NETWORK,
  ],
  SHARED_FROM_OUTSIDE_ACTIVE_NETWORK: [
    ShareContexts.SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_GROUP,
    ShareContexts.SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_NETWORK,
  ],
  SHARED_TO_GROUP: [
    ShareContexts.SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_GROUP,
    ShareContexts.SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_GROUP,
  ],
  SHARED_TO_NETWORK: [
    ShareContexts.SHARED_FROM_WITHIN_ACTIVE_NETWORK_TO_NETWORK,
    ShareContexts.SHARED_FROM_OUTSIDE_ACTIVE_NETWORK_TO_NETWORK,
  ],
}

export {
  AppContexts,
  ConfigureContexts,
  SharedSavedFilterAppContexts,
  ContextViewStates,
  PostContexts,
  ShareContexts,
  ShareContextGroups,
}
