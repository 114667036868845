let ssoOnlyGroupCheck = (group) => {
  if (!group) return false
  let nSso = _.get(group, "is_network_sso_enabled")
  let nKauth = _.get(group, "is_network_kite_auth_enabled")
  let gSso = _.get(group, "sso_auth_enabled")
  let kauth = _.get(group, "kite_auth_enabled")

  // If network SSO is enabled and network Nucla auth is disabled
  if (nSso && !nKauth) return true

  // If group SSO is enabled and group Nucla auth is disabled
  if (gSso && !kauth) return true

  // Otherwise, this isn't an SSO only group
  return false
}

let ssoOnlyNetworkCheck = (network) => {
  if (!network) return false
  let nSso = _.get(network, "sso_auth_enabled")
  let kauth = _.get(network, "kite_auth_enabled")

  // If network SSO is enabled and network Nucla auth is disabled
  if (nSso && !kauth) return true

  // Otherwise, this isn't an SSO only network
  return false
}

export { ssoOnlyGroupCheck, ssoOnlyNetworkCheck }
