import {
  getTypeformPath,
  getTypeformElementType,
  getTypeformHeaders,
  getTypeformItems,
  formatTypeformHeaderText,
} from "@mixins/integrations/typeform"

import get from "lodash/get"
import StaticHelper from "@mixins/static-helpers"
import moment from "moment"
import normalizeUrl from "normalize-url"
import formatter from "@mixins/formatters/money"
import cacheValues from "@mixins/formatters/cacheValues"

const ProviderPrefixToHumanReadableMapping = {
  clb: "Clearbit",
  cb: "Crunchbase",
  owl: "Owler",
  sw: "SimilarWeb", // No longer supported
  pb: "PitchBook",
  slf: "Salesforce",
  msd365: "Microsoft Dynamics 365",
  afi: "Affinity",
}

const Providers = {
  TYPEFORM: 0,
  PITCHBOOK: 1,
}

const ProvidersHumanReadable = {
  0: "Typeform",
  1: "PitchBook",
}

const ProviderLogos = {
  0: "typeform-icon.svg",
  1: "pitchbook-icon.svg",
}

function getProviderPath(provider, obj) {
  if (provider === Providers.TYPEFORM) {
    return getTypeformPath(obj)
  }
}

function getProviderElementType(provider, obj) {
  if (provider === Providers.TYPEFORM) {
    return getTypeformElementType(obj)
  }
}

function getProviderHeaders(provider, obj) {
  if (provider === Providers.TYPEFORM) {
    return getTypeformHeaders(obj)
  }
}

function getProviderItems(provider, obj) {
  if (provider === Providers.TYPEFORM) {
    return getTypeformItems(obj)
  }
}

function formatProviderHeaderText(provider, text) {
  if (provider === Providers.TYPEFORM) {
    return formatTypeformHeaderText(text)
  }
}

export {
  // Constants
  Providers,
  ProviderLogos,
  ProvidersHumanReadable,
  ProviderPrefixToHumanReadableMapping,
  // Helpers
  getProviderPath,
  getProviderItems,
  getProviderHeaders,
  getProviderElementType,
  // Formatting
  formatProviderHeaderText,
}
