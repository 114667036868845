import IndexHelpers from "@utils/directives/index-helpers"
export default {
  parseForAutocomplete: function (payload) {
    let acData = []
    const models = Object.keys(payload.data).filter((value) => {
      return value !== "meta"
    })
    let count = models.length
    models.forEach((modelType) => {
      count--
      acData.push({ header: IndexHelpers.indexToNamespace(modelType) })
      acData = [...acData, ...payload.data[modelType]]
      if (count > 1) {
        acData.push({ divider: true })
      }
    })

    return acData
  },
}
