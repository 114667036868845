<template>
  <v-container
    v-if="!_.isEmpty(sessionObject) || !_.isEmpty(shareToken)"
    class="loading-container"
  >
    <v-row class="fill-height" align="center" justify="center">
      <v-progress-circular
        :size="50"
        color="#1C1836"
        indeterminate
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  page: {
    title: "Loading page...",
  },
  computed: {
    ...mapGetters({
      sessionObject: "auth/sessionObject",
      shareToken: "auth/shareToken",
    }),
  },
}
</script>

<style scoped lang="scss">
div.loading-container {
  height: 50vh;
  min-height: 500px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
</style>
