import Anchor from "@mixins/fields/anchor"
import normalizeUrl from "normalize-url"
const FakeDomain = "kiteplatform.com"

export default {
  isBlankText(input) {
    // The regex will match strings composed only of spaces, tabs, line breaks, carriage returns, and #line_break (possibly with spaces around it)
    // This function also takes into account the "empty state" of output from TipTap
    const regex = /^(\s*#line_break\s*|\s)*$/
    return regex.test(input)
  },
  blank(obj) {
    // A value is blank if it is empty or a whitespace string.
    if ("" + obj + "" === "NaN") {
      return true
    }

    if (
      this.isEmpty(obj) ||
      (typeof obj === "string" && obj.match(/\S/) === null) ||
      (typeof obj === "string" && this.isBlankText(obj))
    ) {
      return true
    }

    if (obj.constructor === Object) {
      return Object.keys(obj).length === 0
    }

    return false
  },
  isEmpty(obj) {
    // Verifies that a value is `null` or an empty string, empty array, or empty function.
    var none = obj === null || obj === undefined

    if (none) {
      return none
    }

    if (typeof obj.size === "number") {
      return !obj.size
    }

    var objectType = typeof obj

    if (objectType === "object") {
      var size = obj.size
      if (typeof size === "number") {
        return !size
      }
    }

    if (typeof obj.length === "number" && objectType !== "function") {
      return !obj.length
    }

    if (objectType === "object") {
      var length = obj.length
      if (typeof length === "number") {
        return !length
      }
    }

    return false
  },
  camelToPascalCase(str) {
    if (_.isString(str)) {
      return str
        .match(/[a-z]+/gi)
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.substr(1)
        })
        .join("")
    }
  },
  wordify(str, separator = "") {
    if (!str) {
      return
    }

    return str
      .toLowerCase()
      .replace(/[^\w]|\s/gi, separator)
      .replace(/[!@#$%^&*(),.?":{}|<>-]/gi, separator)
  },
  urlWithProtocol(str) {
    if (!str) {
      return
    }

    if (str.toLowerCase().match(/^(http|https):/)) {
      return str
    } else {
      return `http://${str}`
    }
  },
  isValidCorporateEmailAddress(email) {
    return (
      !this.blank(email) &&
      this.blank(
        email.match(/(@(gmail)|@(yahoo)|@(hotmail)|@(aol)|@(me))+\.com/i)
      )
    )
  },
  arrDiff(arr1, arr2) {
    return arr1.filter((x) => !arr2.includes(x))
  },
  validNumberCheck(value) {
    const floatVal = parseFloat(value)
    const intVal = parseInt(value)
    return _.isNaN(floatVal)
      ? false
      : String(floatVal) === String(value) || String(intVal) === String(value)
  },
  // If this algo changes, make sure to update it in the backend repo in UrlHelper.fake_domain
  fakeUrl(companyName) {
    if (!companyName) {
      return null
    }
    return _.toLower(
      `${companyName
        .trim()
        .replace(/[^a-z0-9\s-]/i, "")
        .replace(/\s/g, "-")}.${FakeDomain}`
    )
  },
  tryParseJson(jsonString) {
    try {
      const o = JSON.parse(jsonString)

      if (o && typeof o === "object") {
        return o
      }
    } catch (e) {}
    return false
  },
  tryParseUrl(searchInput) {
    if (_.isEmpty(searchInput) || searchInput.trim().length === 0) {
      return searchInput
    }

    // Check if input contains URL.
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    const regex = new RegExp(expression)
    const parsedUrl = searchInput.match(regex)

    // If so, return URL with protocol and www stripped.
    if (!_.isEmpty(parsedUrl)) {
      searchInput = parsedUrl[0]
      // Normalize urls for better search results
      if (Anchor.isUrl(searchInput)) {
        searchInput = normalizeUrl(searchInput, {
          stripProtocol: true,
          stripWWW: true,
        })
        // Remove path if it exists
        if (searchInput.match(/\//)) {
          searchInput = searchInput.substring(0, searchInput.indexOf("/"))
        }
      }

      return searchInput
    } else {
      return searchInput
    }
  },
  // Pass one or two arguments and the this function will output numeralJS format
  numeralFormatForRender(val, rangeMax = null) {
    if (_.isNil(val)) return "0"
    let number = !_.isNil(rangeMax) ? rangeMax : val
    number = Number(number).toPrecision()
    if (number >= 1000000000 || number <= -1000000000) {
      // this will display b or t abbreviation for numbers over 1 billion
      return "0,00[.][00]a"
    } else {
      // Javascript will only allow up to 15 decimal places before it rounds
      return "0,0[.][0000000000000000]"
    }
  },
  isNumber(str) {
    return str.match(/^([-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?)$/g)
  },
  isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str)
  },
  escapeRegExp(str) {
    // Reference MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  },
}

// // https://date-fns.org/docs/parse
// import parseDate from "date-fns/parse";
// // https://date-fns.org/docs/distanceInWords
// import distanceInWords from "date-fns/distance_in_words";
// // https://date-fns.org/docs/isToday
// import isToday from "date-fns/is_today";

// export default function formatDateRelative(fromDate, toDate = new Date()) {
//   fromDate = parseDate(fromDate);
//   toDate = parseDate(toDate);
//   return distanceInWords(fromDate, toDate) + (isToday(toDate) ? " ago" : "");
// }
