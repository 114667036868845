import {
  FieldTypes,
  SelectionTypes,
  NumericFieldLimits,
  NumericFieldLimitsHumanReadable,
} from "@mixins/fields/types"
import StaticHelper from "@mixins/static-helpers"

const SelectionTypesForImport = [
  FieldTypes.USER,
  FieldTypes.DROPDOWN,
  FieldTypes.RADIO,
]

const flatfileUseCases = {
  USERS: 0,
  COMPANIES: 1,
}

const flatfileSithOptions = {
  USERS: {
    type: flatfileUseCases.USERS,
    showFilters: true,
    enableExport: true,
  },
  COMPANIES: {
    type: flatfileUseCases.COMPANIES,
    showFilters: false,
    enableExport: false,
  },
}

const addCompaniesConfig = {
  type: "Company",
  title: "Import Company Data",
  allowInvalidSubmit: false,
  managed: true,
  styleOverrides: {
    primaryButtonColor: "#1C1836",
  },
  allowCustom: false,
  disableManualInput: false,
  maxRecords: 5000,
}
const addUsersConfig = {
  type: "User",
  title: "Import Members",
  allowInvalidSubmit: false,
  managed: true,
  styleOverrides: {
    primaryButtonColor: "#1C1836",
  },
  allowCustom: false,
  disableManualInput: false,
  maxRecords: 100,
}

const userValidators = {
  REQUIRED: {
    validate: "required",
    error: "Value is required",
  },
  UNIQUE: {
    validate: "unique",
    error: "Value must be unique",
  },
  EMAIL: {
    validate: "regex_matches",
    regex: "\\S+@\\S+\\.\\S+",
    error: "Must be a valid email",
  },
  PASSWORD: {
    validate: "regex_matches",
    regex:
      "^$|(^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$)",
    error: "Invalid password",
  },
  PASSWORD_LENGTH: {
    validate: "regex_matches",
    regex: "^$|.{8,}",
    error: "Password must be at least 8 characters.",
  },
  PASSWORD_WHITESPACE: {
    validate: "regex_matches",
    regex: "^$|^(?!.* )",
    error: "Password must not contain whitespace.",
  },
  PASSWORD_UPPERCASE: {
    validate: "regex_matches",
    regex: "^$|(?=.*?[A-Z])",
    error: "Password must contain uppercase character.",
  },
  PASSWORD_LOWERCASE: {
    validate: "regex_matches",
    regex: "^$|(?=.*?[a-z])",
    error: "Password must contain lowercase character.",
  },
  PASSWORD_DIGIT: {
    validate: "regex_matches",
    regex: "^$|(?=.*?[0-9])",
    error: "Password must contain numerical digit.",
  },
  PASSWORD_SPECIAL_CHARACTER: {
    validate: "regex_matches",
    regex: "^$|(?=.*?[#?!@$%^&*-])",
    error: "Password must contain non-alphanumeric character.",
  },
  PERMISSIONS: {
    validate: "regex_matches",
    regex: "^(\\s*|[0-2]{1})$",
    error: `Permissions must be an integer between 0-2`,
  },
}

const userFields = [
  {
    label: "Name",
    key: "name",
    description: "User's full name - Required",
    validators: [userValidators.REQUIRED],
  },
  {
    label: "Email",
    key: "email",
    description: "User's email address - Required",
    validators: [
      userValidators.REQUIRED,
      userValidators.EMAIL,
      userValidators.UNIQUE,
    ],
  },
  {
    label: "Title",
    key: "title",
    description: "User's job title",
  },
  {
    label: "Username",
    key: "username",
    description: "User's username - Required",
    validators: [userValidators.REQUIRED, userValidators.UNIQUE],
  },
  {
    label: "Password",
    key: "password",
    description:
      "This field does not affect existing users. Passwords will be automatically provisioned for new users where this field is left blank. Passwords must contain at least 8 characters, one uppercase character, one lowercase character, one numerical digit, and one non-alphanumeric character.",
    validators: [
      userValidators.PASSWORD,
      userValidators.PASSWORD_LENGTH,
      userValidators.PASSWORD_WHITESPACE,
      userValidators.PASSWORD_UPPERCASE,
      userValidators.PASSWORD_LOWERCASE,
      userValidators.PASSWORD_DIGIT,
      userValidators.PASSWORD_SPECIAL_CHARACTER,
    ],
  },
  {
    label: "Permissions",
    key: "permissions",
    description:
      "0 = General User, 1 = Group Admin, 2 = Network Admin. Should be an integer 0-2, and you can only give permissions up to your accounts admin level.",
    validators: [userValidators.PERMISSIONS],
  },
]

function keyFor(field) {
  if (field.source === 0 && ["kt_name", "kt_url"].includes(field.attribute)) {
    return field.attribute === "kt_name" ? "name" : "url"
  } else {
    return `${field.hid}`
  }
}

function typeFor(field) {
  return SelectionTypesForImport.includes(field.use_case) ? "select" : "string"
}

function optionsFor(field, users) {
  if (SelectionTypesForImport.includes(field.use_case)) {
    if (field.use_case === FieldTypes.USER) {
      return _.map(users, (user) => {
        return { value: user.id, label: user.name || user.email }
      })
    } else {
      return _.map(field.choices, (choice) => {
        return { value: choice, label: choice }
      })
    }
  } else {
    return []
  }
}

function descriptionFor(field) {
  if (field.attribute === "kt_name") {
    return "Company name"
  } else if (field.attribute === "kt_url") {
    return "Company url"
  } else if (field.use_case === FieldTypes.TEXT) {
    return "Text field"
  } else if (field.use_case === FieldTypes.RANGE) {
    return "Range field"
  } else if (field.use_case === FieldTypes.DATE) {
    return "Date field"
  } else if (field.use_case === FieldTypes.DROPDOWN) {
    return "Dropdown field"
  } else if (field.use_case === FieldTypes.USER) {
    return "User field"
  } else if (field.use_case === FieldTypes.CHECKBOX) {
    return "Checkbox field"
  } else if (field.use_case === FieldTypes.RADIO) {
    return "Radio field"
  } else if (field.use_case === FieldTypes.NUMBER) {
    return "Number field"
  } else if (field.use_case === FieldTypes.TAG) {
    return "Tag field"
  }
}

function validatorsFor(field) {
  if (field.attribute === "kt_name") {
    return [
      {
        validate: "required",
      },
    ]
  } else if (field.attribute === "kt_url") {
    return [
      {
        validate: "regex_matches",
        regex:
        // eslint-disable-next-line no-useless-escape
          "^(?:http(s)?:\\\/\\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:\/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$", // prettier-ignore
        error: "Must be a valid url",
      },
      {
        validate: "required",
      },
    ]
  } else if (field.use_case === FieldTypes.TEXT) {
    if (field.max_characters) {
      return [
        {
          validate: "regex_matches",
          regex: `^.{0,${field.max_characters}}$`,
          error: `Character limit of ${field.max_characters} exceeded`,
        },
      ]
    } else {
      return []
    }
  } else if (field.use_case === FieldTypes.RANGE) {
    // Number and range validations are handled by field and record hooks
    return []
  } else if (field.use_case === FieldTypes.DATE) {
    const regex =
      "(((0[1-9]|1[012])[\\.\\-\\/](0[1-9]|[12][0-9]|3[01])[\\.\\-\\/](19|20)\\d\\d)|([12]\\d{3}[\\.\\-\\/](0[1-9]|1[0-2])[\\.\\-\\/](0[1-9]|[12]\\d|3[01])))"
    return [
      {
        validate: "regex_matches",
        regex,
        error:
          'Date format must match YYYY-MM-DD or MM-DD-YYYY. Valid separators include ".", "-", and "/".',
      },
    ]
  } else if (field.use_case === FieldTypes.DROPDOWN) {
    return []
  } else if (field.use_case === FieldTypes.USER) {
    return []
  } else if (field.use_case === FieldTypes.CHECKBOX) {
    return []
  } else if (field.use_case === FieldTypes.RADIO) {
    return []
  } else if (field.use_case === FieldTypes.NUMBER) {
    // Number and range validations are handled by field and record hooks
    return []
  } else if (field.use_case === FieldTypes.TAG) {
    return []
  }
}

// This is legacy code that pulled the checkbox options out of the gfc
// and presented them to the user for mapping. It only allows for 1:1 mapping
// and was removed when we opted to let the user import multiple options.

const flatMapCheckboxChoices = function (choices) {
  return _.reduce(
    choices,
    (arr, choice) => {
      // arr.push({ value: choice.hid, label: choice.choice })
      if (choice.choices.length) {
        if (choice.choice) arr.push(choice.choice)
        return arr.concat(flatMapCheckboxChoices(choice.choices))
        // Added to support validations for multi-checkboxes
      } else {
        arr.push(choice.choice)
      }
      return arr
    },
    []
  )
}

function detectDuplicateCheckboxChoices(checkboxChoices) {
  let choices = new Set()
  let dupes = new Set()
  checkboxChoices.forEach((choice) => {
    // if the choice is already in the choices set, then we have a dupe
    if (choices.has(choice)) dupes.add(choice)
    // Always add the choice to the choices set. Sets only allow the same value
    // to be entered once, so adding a duplicate will not cause duplicates
    choices.add(choice)
  })
  return dupes
}

function configureField(field, users) {
  return {
    key: keyFor(field),
    label: field.label,
    description: descriptionFor(field),
    type: typeFor(field),
    options: optionsFor(field, users),
    validators: validatorsFor(field),
  }
}

function mapCompanyFields(groupFieldData) {
  return _.map(groupFieldData.groupFields, (field) => {
    return configureField(field, groupFieldData.users)
  })
}

function preProcessString(string) {
  return string.replace(/“|”/g, '"') //.replace(/‘|’/g, "'")
}

// | something, something else |
function attemptSanitizedMatch(value, choices) {
  if (!value) return null
  const specialCharRegEx = new RegExp(/[^a-zA-Z0-9]/g)
  let testValue = value

  //is there a wrapper of special characters
  const firstChar = value[0]
  const lastChar = value[value.length - 1]

  // if the chars are the same and they are both special chars
  // then its likely we have a wrapper. This will also catch wrapping spaces
  if (firstChar === lastChar && specialCharRegEx.test(firstChar)) {
    // remove the wrapper and leading/trailing whitespace
    testValue = testValue.substring(1, value.length - 1).trim()
    if (choices.includes(testValue)) return testValue
  }

  if (/‘|’/g.test(value)) {
    const newVal = value.replace(/‘|’/g, "'").trim()
    if (choices.includes(newVal)) return newVal
  }

  if (/\'/g.test(value)) {
    let newVal = value.replace(/\'/g, "‘").trim()
    if (choices.includes(newVal)) return newVal
    newVal = value.replace(/\'/g, "’").trim()
    if (choices.includes(newVal)) return newVal
  }

  // If we still don't have a match, nuke all special characters and remove whitespace
  testValue = testValue.replace(/[^a-zA-Z0-9]/g, "").trim()

  return choices.includes(testValue) ? testValue : null
}

function CSVtoArray(text) {
  //https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
  const re_valid =
    /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/
  const re_value =
    /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g
  // Return NULL if input string is not well formed CSV string.

  if (!re_valid.test(text)) return null
  let a = [] // Initialize array to receive values.
  text.replace(re_value, function (m0, m1, m2, m3) {
    // Remove backslash from \' in single quoted values.
    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"))
    // Remove backslash from \" in double quoted values.
    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'))
    else if (m3 !== undefined) a.push(m3)
    return "" // Return empty string.
  })

  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push("")
  return a
}

function recordHookValidations(record, fields) {
  let out = {}
  fields.forEach((field) => {
    let validationMessages = []
    let fieldKey = keyFor(field)
    // Ranges and numbers
    if (
      field.use_case === FieldTypes.RANGE ||
      field.use_case === FieldTypes.NUMBER
    ) {
      if (
        // If value is not in min/max range
        !_.isEmpty(record[fieldKey]) &&
        record[fieldKey] != field.max_value &&
        record[fieldKey] != field.min_value &&
        !_.inRange(record[fieldKey], field.min_value, field.max_value)
      ) {
        validationMessages.push({
          message: `Value must be a number within range: ${field.min_value} - ${field.max_value}`,
          level: "error",
        })
      }
      if (
        // If the number is outside the indexable range
        record[fieldKey] < NumericFieldLimits.LOWER ||
        record[fieldKey] > NumericFieldLimits.UPPER
      ) {
        validationMessages.push({
          message: `Value exceeds allowable numeric range ${NumericFieldLimitsHumanReadable.LOWER} - ${NumericFieldLimitsHumanReadable.UPPER}`,
          level: "error",
        })
      }
      if (
        !_.isEmpty(record[fieldKey]) &&
        !StaticHelper.isNumber(record[fieldKey])
      ) {
        validationMessages.push({
          message: `Value must be a number`,
          level: "error",
        })
      }
    }
    // Numbers
    // if (field.use_case === FieldTypes.NUMBER) {}

    // Ranges
    if (field.use_case === FieldTypes.RANGE) {
      // Range values cannot be floats
      if (
        !_.isEmpty(record[fieldKey]) &&
        record[fieldKey].match(/^[+-]?\d+(\.\d+)$/g)
      ) {
        validationMessages.push({
          message: `Value must be an integer`,
          level: "error",
        })
      }
    }

    // Checkboxes
    if (field.use_case === FieldTypes.CHECKBOX) {
      if (_.isNil(record[fieldKey]) || record[fieldKey] === "") return

      let invalidValues = []
      let duplicateChoices = []
      let validValues = []
      // Return valid CSV values
      const values = CSVtoArray(preProcessString(record[fieldKey]))

      if (values && values.length) {
        const fieldValues = flatMapCheckboxChoices(field.choices)
        // duplicateCheckboxChoices is a set
        const duplicateCheckboxChoices =
          detectDuplicateCheckboxChoices(fieldValues)
        // Check each value for a matching choice
        values.forEach((val) => {
          // Check to see if the choice exists more than one time
          if (duplicateCheckboxChoices.has(val)) {
            duplicateChoices.push(val)
            // Checkbox choices do not allow for leading or trailing whitespace
            // ensure accuracy by trimming the value prior to matching
          } else if (fieldValues.includes(val.trim())) {
            validValues.push(val.trim())
          } else {
            // Remove cruft and try to match again
            const sanitizedInvalidValue = attemptSanitizedMatch(
              val,
              fieldValues
            )
            // If we still dont have a match, kick the value out
            _.isNil(sanitizedInvalidValue)
              ? invalidValues.push(val)
              : validValues.push(sanitizedInvalidValue)
          }
        })
      } else {
        if (record[fieldKey].includes("'") || record[fieldKey].includes('"')) {
          validationMessages.push({
            message: `Value included a single quote (') or a double quote (") which can cause parsing errors. Try wrapping your input in single or double quotes to yield the desired outcome. For example, Mary's Client should become "Mary's Client" or Dwayne "The Rock" Johnson should become 'Dwayne "The Rock" Johnson'.`,
            level: "warning",
          })
        }
        validationMessages.push({
          message: `Unable to match input of "${record[fieldKey]}" due to invalid CSV formatting. Please reformat your choices and try again.`,
          level: "warning",
        })
      }

      if (invalidValues.length) {
        validationMessages.push({
          message: `Unable to match the following values with an acceptable choice: ${invalidValues.join(
            ","
          )}. These values have been removed`,
          level: "warning",
        })
      }

      if (duplicateChoices.length) {
        validationMessages.push({
          message: `The following checkbox choices are not unique: ${duplicateChoices.join(
            ","
          )}. These values will not be imported. You can select them manually or edit the field so that the values of the choices are unique.`,
          level: "warning",
        })
      }
      out[fieldKey] = {
        value: validValues
          .map((val) => (val.includes(",") ? `"${val}"` : val))
          .join(", "),
      }
    }

    // Add any errors to the output object
    if (validationMessages.length) {
      validationMessages.push({
        message:
          "Correct this error by directly modifying the value in the cell",
        level: "info",
      })
      out[fieldKey]
        ? (out[fieldKey].info = validationMessages)
        : (out[fieldKey] = { info: validationMessages })
    }
  })
  return out
}

// This function produces the correct output for FF field hooks. It is not currently in use
// index is passed from the values array and is required - required
// alets is required. It is an array of objects - [{message: "foobar", level: "info"}, {message: "hello world", level: warning}]
// this expression can be reformatted to accept an array of messages and levels
// value is the value you want returned by the hook - optional
// function formatFieldHook(index, alerts, value = null) {
//   if (!_.isNil(value)) {
//     return [
//       {
//         value,
//         info: alerts,
//       },
//       index,
//     ]
//   } else {
//     return [
//       {
//         info: alerts,
//       },
//       index,
//     ]
//   }
// }

export {
  flatfileUseCases,
  flatfileSithOptions,
  addCompaniesConfig,
  addUsersConfig,
  userFields,
  mapCompanyFields,
  keyFor,
  recordHookValidations,
  //formatFieldHook,
}
