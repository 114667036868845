// These widget types indices map directly to the backend
// WidgetHelper mappings for  widget use case. Ensure these
// are kept up to date
import { FieldTypes } from "@mixins/fields/types"
import { DataSources } from "@mixins/data-sources"

const WidgetTypes = {
  TEXT_ENTRY: 0,
  FIELD_GRAPH: 1,
  FILE_UPLOAD: 2,
  FILTER_GRAPH: 3,
  KANBAN_BOARD: 4,
  SECTOR_MAP: 5,
  PIGEONHOLE_EMBED: 6,
  VIDEO_EMBED: 7,
  COMPANY_OVERVIEW: 8,
}

const WidgetTypesHumanReadable = {
  0: "Text Box",
  1: "Field Graph",
  2: "File Upload",
  3: "Filter Graph",
  4: "Kanban Board",
  5: "Sector Map",
  6: "Pigeonhole Embed",
  7: "Video Widget",
  8: "Company Overview",
}

// These are the types and order of widgets used on the Add Widget modal.
// They do not necessarily map to their constants above.
const WidgetIncludedTypes = [
  WidgetTypes.TEXT_ENTRY,
  WidgetTypes.FIELD_GRAPH,
  WidgetTypes.COMPANY_OVERVIEW,
  WidgetTypes.FILTER_GRAPH,
  WidgetTypes.FILE_UPLOAD,
  WidgetTypes.KANBAN_BOARD,
  WidgetTypes.SECTOR_MAP,
  WidgetTypes.PIGEONHOLE_EMBED,
  WidgetTypes.VIDEO_EMBED,
]

const WidgetDimensionTypes = {
  HALF_WIDTH: 0,
  THREE_FOURTH_WIDTH: 1,
  FULL_WIDTH: 2,
  MISC_FULL_WIDTH: 3,
  ONE_FOURTH_WIDTH: 4,
}

const WidgetSubmitVerbs = {
  0: "Save Text Box",
  1: "Save Field Graph",
  2: "Save File Upload",
  3: "Save Filter Graph",
  4: "Save Kanban Board",
  5: "Save Sector Map",
  6: "Save Pigeonhole Embed",
  7: "Save Video Widget",
  8: "Save Company Overview Widget",
}

const allowedFieldGraphFieldTypes = [
  FieldTypes.DROPDOWN,
  FieldTypes.CHECKBOX,
  FieldTypes.RADIO,
  FieldTypes.TAG,
  FieldTypes.TEXT,
  FieldTypes.USER,
]

const allowedKanbanFieldTypes = [FieldTypes.DROPDOWN, FieldTypes.RADIO]
const allowedKanbanFieldSources = [DataSources.GROUP]
const allowedSectorMapFieldTypes = [FieldTypes.CHECKBOX]
const allowedSectorMapFieldSources = [
  DataSources.KITE,
  DataSources.GROUP,
  DataSources.CRUNCHBASE,
  DataSources.CLEARBIT,
  DataSources.OWLER,
  DataSources.TYPEFORM,
  DataSources.SHARED,
  // DataSources.SIMILAR_WEB,
  // Excludes:
  // - System Fields (sys_all_forms) - SYSTEM
  // - Tray Integrations - SALESFORCE, MICROSOFT_DYNAMICS_365
]

const WidgetVideoProviders = {
  YOUTUBE: 0,
}

const WidgetVideoProvidersHumanReadable = {
  0: "YouTube",
}

export {
  WidgetTypes,
  WidgetTypesHumanReadable,
  WidgetIncludedTypes,
  WidgetDimensionTypes,
  allowedFieldGraphFieldTypes,
  allowedKanbanFieldTypes,
  allowedKanbanFieldSources,
  allowedSectorMapFieldTypes,
  allowedSectorMapFieldSources,
  WidgetSubmitVerbs,
  WidgetVideoProviders,
  WidgetVideoProvidersHumanReadable,
}
