/* eslint-disable no-useless-escape */
import StaticHelper from "@mixins/static-helpers"

const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi
const emailRegex =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export default {
  urlRegex,
  isUrl(text) {
    return isUrl(text)
  },
  isEmail(text) {
    if (StaticHelper.blank(text) || !_.isString(text)) {
      return null
    }
    return !!text.match(emailRegex)
  },
  mailTo(text) {
    return `mailto:${text}`
  },
  urlDomain(value) {
    if (isUrl(value)) return cleanAnchor(value).hostname
    return value
  },
  urlify(value) {
    if (isUrl(value)) return cleanAnchor(value).href
    return value
  },
  sectionFromPath(path) {
    const reg = /^[\/]([^\/]+)/
    const section = reg.exec(path)
    return section === null ? section : section[1] // Regex returns something like: ["/reports", "reports", index: 0, input: "/reports/1/all-companies", groups: undefined]
  },
  open(router, option) {
    if (option.route === "user") {
      router.push({
        name: option.route,
        params: { id: option.id },
      })
    } else if (option.route) {
      const fullRoute = _.get(option, "fullRoute")
      if (fullRoute) {
        router.push(fullRoute)
      } else {
        router.push({ name: option.route })
      }
    } else {
      window.open(option.path, "_blank", "noopener noreferrer")
    }
  },
}

function cleanAnchor(value) {
  // Check and correct missing protocols so the hostname reflects correctly.
  if (!_.startsWith(value, "http://") && !_.startsWith(value, "https://")) {
    value = "http://" + value
  }
  const a = document.createElement("a")
  a.href = value
  return a
}

function isUrl(value) {
  if (StaticHelper.blank(value) || typeof value.match != "function") {
    return null
  }
  return !!value.match(urlRegex)
}
