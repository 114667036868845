export default {
  models(models) {
    const inc = { models: [] }

    // This may seem complicated but do not fear; it's not! Data will be passed into this function as an array of arrays.
    // i.e. [["relation", "saved_filters"], ...]
    // Each include pattern will take in attributes differently, so all the following is doing is looping through the
    // params of this function and compiling the proper include object for the include pattern. See; easy!
    for (let i = 0; i < models.length; i++) {
      if (models[i].length > 0) {
        if (models[i][0] === "relation") {
          inc.models.push({ lookup_type: models[i][0], key: models[i][1] })
        }
      }
    }

    // ...And finally we just return the include object.
    return inc
  },
}
