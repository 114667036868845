var numeral = require("numeral")

const CompanyProfileState = {
  company: null,
  report: null,
  activeSectionContext: {
    index: 0,
    search: null,
    isEditing: false,
  },
}

const CompanyProfileGetters = {
  company: (state) => {
    return _.get(state, "company")
  },
  report: (state) => {
    return _.get(state, "report")
  },
  activeSectionContext: (state) => {
    return _.get(state, "activeSectionContext")
  },
}

const CompanyProfileActions = {
  reset: ({ commit, dispatch, rootGetters }) => {
    commit("RESET_STATE")
    if (rootGetters["fieldCardGrid/isEditing"]) {
      // reset edit state for field card grid when closing sheet
      dispatch("fieldCardGrid/toggleEditState", null, { root: true })
    }
  },
  set: ({ commit }, obj) => {
    commit("SET_VALUE", obj)
  },
}

const CompanyProfileMutations = {
  SET_VALUE(state, obj) {
    state[obj.k] = obj.v
  },
  SET_STATE(state, obj) {
    Object.assign(state, obj)
  },
  RESET_STATE(state) {
    Object.assign(state, {
      company: null,
      report: null,
      activeSectionContext: {
        index: 0,
        search: null,
        isEditing: false,
      },
    })
  },
}

export {
  CompanyProfileState,
  CompanyProfileActions,
  CompanyProfileGetters,
  CompanyProfileMutations,
}
